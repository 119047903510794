import Typography from "@mui/material/Typography";
import { ServiceItem } from "../../model/order.model";
import { formatPrice } from "../../utils/calculations";

interface IProps {
    service: ServiceItem;
}

const DecoratedPrice = (props: IProps) => {
    const renderDecoratedPrice = () => {
        const price = props.service.price;
        const formattedPrice = formatPrice(props.service.price);
        const actualPrice = props.service.actualPrice;
        return (
            <span>
                <span>{formattedPrice}</span>

                {actualPrice && actualPrice > price && (
                    <span>
                        <span
                            style={{
                                color: "grey",
                                textDecoration: "line-through",
                                marginLeft: "10px",
                                fontSize: "14px",
                            }}
                        >
                            {formatPrice(actualPrice)}
                        </span>
                        <span
                            style={{
                                color: "white",
                                marginLeft: "4px",
                                fontSize: "11px",
                                backgroundColor: "#f44336",
                                padding: "2px",
                                borderRadius: "10px 5px 5px 10px",
                                verticalAlign: "text-bottom",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {" • promotion"}
                        </span>
                    </span>
                )}
            </span>
        );
    };

    return (
        <Typography variant="h6" color="text.primary" component="div">
            {renderDecoratedPrice()}
        </Typography>
    );
};

export default DecoratedPrice;
