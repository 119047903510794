import AutoAwesomeMosaicTwoToneIcon from "@mui/icons-material/AutoAwesomeMosaicTwoTone";
import BrushIcon from "@mui/icons-material/Brush";
import ContactPageTwoToneIcon from "@mui/icons-material/ContactPageTwoTone";
import CottageTwoToneIcon from "@mui/icons-material/CottageTwoTone";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import MenuIcon from "@mui/icons-material/Menu";
import PhotoLibraryTwoToneIcon from "@mui/icons-material/PhotoLibraryTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import ShieldTwoToneIcon from "@mui/icons-material/ShieldTwoTone";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    children: React.ReactElement;
    show: boolean;
}

function HideOnScroll(props: Props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger || props.show}>
            {children}
        </Slide>
    );
}

function ResponsiveAppBar() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const navigate = useNavigate();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMyOrdersClick = () => {
        navigate("/my-orders");
    };

    const handlePayClick = () => {
        navigate("/pay");
    };

    const handleLogoClick = () => {
        navigate("/");
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setDrawerOpen(open);
    };

    const renderDrawer = () => (
        <Box
            sx={{
                width: 250,
                background:
                    "linear-gradient(313deg, rgba(255,236,177,1) 0%, rgba(255,250,172,1) 35%, rgba(244,255,175,1) 100%)",
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <ListItemIcon>{<CottageTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/paintings");
                        }}
                    >
                        <ListItemIcon>{<PhotoLibraryTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Paintings" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/services");
                        }}
                    >
                        <ListItemIcon>{<BrushIcon />}</ListItemIcon>
                        <ListItemText primary="Services" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/gallery");
                        }}
                    >
                        <ListItemIcon>{<AutoAwesomeMosaicTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Gallery" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/about");
                        }}
                    >
                        <ListItemIcon>{<ContactPageTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="About" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            navigate("/privacy");
                        }}
                    >
                        <ListItemIcon>{<ShieldTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Privacy policy" />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleMyOrdersClick}>
                        <ListItemIcon>{<Inventory2TwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Track orders" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={handlePayClick}>
                        <ListItemIcon>{<ReceiptTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary="Payment" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <CssBaseline />
            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{
                    marginTop: 7,
                }}
                PaperProps={{
                    sx: {
                        marginTop: 7,
                        background:
                            "linear-gradient(313deg, rgba(255,225,177,1) 0%, rgba(255,241,172,1) 35%, rgba(255,253,175,1) 100%)",
                    },
                }}
                slotProps={{ backdrop: { sx: { marginTop: 7 } } }}
            >
                {renderDrawer()}
            </SwipeableDrawer>
            <HideOnScroll show={drawerOpen}>
                <AppBar
                    sx={{
                        background:
                            "linear-gradient(169deg, rgba(230,81,0,1) 0%, rgba(239,108,0,1) 35%, rgba(249,168,37,1) 100%)",
                    }}
                >
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box
                                component="img"
                                sx={{
                                    width: "50px",
                                    display: { xs: "none", md: "flex" },
                                    mr: "10px",
                                    padding: "5px",
                                }}
                                alt="KishoreKambali's website logo"
                                src="/logo.png"
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: "none", md: "flex" },
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    letterSpacing: ".3rem",
                                    color: "inherit",
                                    textDecoration: "none",
                                    ":hover": { color: "white" },
                                    userSelect: "none",
                                    cursor: "pointer",
                                }}
                                onClick={handleLogoClick}
                            >
                                KishoreKambali
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, mr: -1 }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                {/* <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={() => navigate("/")}
                                    color="inherit"
                                >
                                    <CottageTwoToneIcon />
                                </IconButton> */}
                            </Box>

                            <Box
                                component="img"
                                sx={{
                                    width: "32px",
                                    display: { xs: "flex", md: "none" },
                                    mr: "5px",
                                    padding: "5px",
                                }}
                                alt="KishoreKambali's website logo"
                                src="/logo.png"
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                sx={{
                                    mr: 2,
                                    display: { xs: "flex", md: "none" },
                                    flexGrow: 1,
                                    fontFamily: "monospace",
                                    fontWeight: 400,
                                    // letterSpacing: ".3rem",
                                    color: "inherit",
                                    textDecoration: "none",
                                    ":hover": { color: "white" },
                                    userSelect: "none",
                                    cursor: "pointer",
                                }}
                                onClick={handleLogoClick}
                            >
                                KishoreKambali
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                <MenuItem
                                    key="ab0"
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    <Typography textAlign="center">Home</Typography>
                                </MenuItem>
                                <MenuItem
                                    key="ab4"
                                    onClick={() => {
                                        navigate("/paintings");
                                    }}
                                >
                                    <Typography textAlign="center">Paintings</Typography>
                                </MenuItem>
                                <MenuItem
                                    key="ab1"
                                    onClick={() => {
                                        navigate("/services");
                                    }}
                                >
                                    <Typography textAlign="center">Services</Typography>
                                </MenuItem>
                                <MenuItem
                                    key="ab2"
                                    onClick={() => {
                                        navigate("/gallery");
                                    }}
                                >
                                    <Typography textAlign="center">Gallery</Typography>
                                </MenuItem>
                                <MenuItem
                                    key="ab3"
                                    onClick={() => {
                                        navigate("/about");
                                    }}
                                >
                                    <Typography textAlign="center">About</Typography>
                                </MenuItem>
                                <MenuItem key="myOrders" onClick={handleMyOrdersClick}>
                                    <Typography textAlign="center">Track orders</Typography>
                                </MenuItem>
                                <MenuItem key="pay" onClick={handlePayClick}>
                                    <Typography textAlign="center">Payment</Typography>
                                </MenuItem>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <Box sx={{ height: "50px" }} />
        </>
    );
}
export default ResponsiveAppBar;
