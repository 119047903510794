import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Button } from "@mui/material";
import { useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";

const VideoPort = (props: { url: string }) => {
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);

    return (
        <div className="container focused nopadding hasplayer">
            <div className="blur" />
            <div className="other-container even back">
                <Waypoint
                    onEnter={() => setPlaying(true)}
                    onLeave={() => setPlaying(false)}
                    topOffset={-200}
                    bottomOffset={400}
                    fireOnRapidScroll={false}
                >
                    <div className="player-wrapper" />
                </Waypoint>
                <ReactPlayer
                    url={props.url}
                    controls
                    width="100%"
                    height="100%"
                    volume={0.1}
                    playsinline
                    playing={playing}
                />
                <div className="text overlay">
                    <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={() => navigate("/gallery")}
                        sx={{ margin: "auto" }}
                        endIcon={<DoubleArrowIcon />}
                    >
                        More like this
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default VideoPort;
