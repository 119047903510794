import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Button, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { Painting } from "../../model/painting.model";
import { getThumbnailUrl } from "../util/net-util";
import "./paintings-top.scss";
import viewMore from "./view-more.jpg";

interface IProps {
    title: string;
}

const PaintingsTop = (props: IProps) => {
    const navigate = useNavigate();
    const { loadingPaintings, paintings } = React.useContext(AppContext) as IAppContext;

    useEffect(() => {
        if (props.title) {
            document.title = props.title;
        }
    }, []);

    const openPainting = (id: number) => {
        navigate(`/paintings/${id}`);
    };

    // sort on price by default
    paintings.sort((p1, p2) => {
        if (p1.status === "FOR_SALE") {
            return 1;
        } else if (p1.status === "NOT_FOR_SALE") {
            return -1;
        }
        return p2.points - p1.points;
    });

    return (
        <Box className="paintings">
            <div className="title">Recent artworks...</div>
            <div className="images">
                {loadingPaintings && (
                    <Box p={3} width="100%">
                        {[...Array(2)].map((e, i) => (
                            <Skeleton
                                key={`card-skeleton-${i}`}
                                variant="rectangular"
                                height={100}
                                sx={{ margin: 1 }}
                                animation="wave"
                            />
                        ))}
                    </Box>
                )}
                {paintings.slice(0, 5).map((painting: Painting) => (
                    <div
                        className="image-container"
                        key={painting.name}
                        onClick={() => openPainting(painting.id)}
                        tabIndex={0}
                        onKeyDown={() => openPainting(painting.id)}
                    >
                        <img
                            className="image"
                            alt={`${painting.name} by Kishore Anand Kambali (@kishorekambali)`}
                            src={getThumbnailUrl(painting.photos[0])}
                        />
                    </div>
                ))}
                {!loadingPaintings && (
                    <div
                        className="image-container"
                        onClick={() => navigate("/paintings")}
                        tabIndex={0}
                        onKeyDown={() => navigate("/paintings")}
                    >
                        <img className="image" alt="view more paintings..." src={viewMore} />
                        <Button
                            className="inner-text"
                            variant="contained"
                            size="small"
                            color="warning"
                            onClick={() => navigate("/services")}
                            sx={{ margin: "auto" }}
                            endIcon={<DoubleArrowIcon />}
                        >
                            Explore
                        </Button>
                    </div>
                )}
            </div>
        </Box>
    );
};

export default PaintingsTop;
