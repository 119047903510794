import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import KeyboardDoubleArrowDown from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { InputAdornment } from "@mui/material";
import { isPhoneValid } from "../../utils/validations";

export interface ContactInfo {
    userId: string;
}

interface ContactInfoProps {
    contactInfo: ContactInfo | null;
    onDone: (info: ContactInfo) => void;
    onBack: () => void;
}

const DEFAULT_PHONE_MESSAGE = "eg. 9034725946";

const ContactInfoForm = (props: ContactInfoProps) => {
    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [phoneMessage, setPhoneMessage] = useState(DEFAULT_PHONE_MESSAGE);

    useEffect(() => {
        setPhone(props.contactInfo ? props.contactInfo.userId : "");
    }, [props.contactInfo]);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredPhone = e.target.value;
        setPhone(enteredPhone);
        if (phoneTouched) validatePhone(enteredPhone);
    };

    const handlePhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const enteredPhone = e.target.value;
        setPhoneTouched(true);
        validatePhone(enteredPhone);
    };

    const validatePhone = (p: string) => {
        const isThePhoneValid = isPhoneValid(p);
        if (!isThePhoneValid) {
            setPhoneValid(false);
            setPhoneMessage("Invalid phone number");
        } else {
            setPhoneValid(true);
            setPhoneMessage(DEFAULT_PHONE_MESSAGE);
        }
        return isThePhoneValid;
    };

    const submitDetails = () => {
        setPhoneTouched(true);
        const isThePhoneValid = validatePhone(phone);
        if (!isThePhoneValid) {
            return;
        }
        props.onDone({ userId: phone });
    };

    const commonSx = {
        width: 300,
    };

    const submitEnabled = () => {
        if (phoneValid) return true;
    };

    return (
        <>
            <Box m={1} p={1}>
                <Typography gutterBottom variant="body1" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                    Give me your phone number in case I want to reach out to you
                </Typography>
                <Stack
                    direction="column"
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                >
                    <TextField
                        id="outlined-helperText"
                        label="Phone"
                        type="text"
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                        value={phone}
                        onChange={handlePhoneChange}
                        onBlur={handlePhoneBlur}
                        sx={commonSx}
                        required
                        helperText={phoneMessage}
                        error={!phoneValid}
                    />
                </Stack>
                <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                    textAlign="center"
                    sx={{ padding: 1, paddingX: 2 }}
                >
                    Please provide valid information otherwise I won't be able to reach you. In case I did not come
                    back, Please message me on Instagram.
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                    <Button variant="text" onClick={props.onBack} startIcon={<KeyboardDoubleArrowUpIcon />}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<KeyboardDoubleArrowDown />}
                        onClick={submitDetails}
                        disabled={!submitEnabled}
                        color="warning"
                    >
                        Continue
                    </Button>
                </Stack>
            </Box>
        </>
    );
};

export default ContactInfoForm;
