import { OrderItem, ServiceItem, UploadedFile } from "../model/order.model";
import { ContactInfo } from "../modules/order/contact-info-form";
import { DeliveryInfo } from "../modules/order/delivery-info-form";

export const KAF_ORDER_ITEM = "kaf_order_item";
export const KAF_CONTACT_INFO = "kaf_contact_info";
export const KAF_DELIVERY_INFO = "kaf_delivery_info";
export const KAF_UPLOADED_FILE = "kaf_uploaded_file";
export const KAF_SERVICE_ITEM = "kaf_service_item";

export const storeOrderItem = (item: OrderItem) => {
    localStorage.setItem(KAF_ORDER_ITEM, JSON.stringify(item));
};

export const getOrderItem = (): null | OrderItem => {
    const storedItem = localStorage.getItem(KAF_ORDER_ITEM);
    if (storedItem) {
        return JSON.parse(storedItem);
    }
    return null;
};

export const getUploadedFile = (index?: number): null | UploadedFile => {
    if (!index) {
        index = 0;
    }
    const storedFile = localStorage.getItem(`${KAF_UPLOADED_FILE}_${index}`);
    if (storedFile) {
        return JSON.parse(storedFile);
    }
    return null;
};

export const storeServiceItem = (serviceItem: ServiceItem | undefined) => {
    if (serviceItem) {
        localStorage.setItem(KAF_SERVICE_ITEM, JSON.stringify(serviceItem));
    } else {
        localStorage.removeItem(KAF_SERVICE_ITEM);
    }
};

export const getServiceItem = (): null | ServiceItem => {
    const stored = localStorage.getItem(KAF_SERVICE_ITEM);
    if (stored) {
        return JSON.parse(stored);
    }
    return null;
};

export const storeUploadedFile = (uploadedFile: UploadedFile | null, index?: number) => {
    if (!index) {
        index = 0;
    }
    if (uploadedFile) {
        localStorage.setItem(`${KAF_UPLOADED_FILE}_${index}`, JSON.stringify(uploadedFile));
    } else {
        localStorage.removeItem(`${KAF_UPLOADED_FILE}_${index}`);
    }
};

export const getContactInfo = (): null | ContactInfo => {
    const stored = localStorage.getItem(KAF_CONTACT_INFO);
    if (stored) {
        return JSON.parse(stored);
    }
    return null;
};

export const storeContactInfo = (contactInfo: ContactInfo | null) => {
    if (contactInfo) {
        localStorage.setItem(KAF_CONTACT_INFO, JSON.stringify(contactInfo));
    } else {
        localStorage.removeItem(KAF_CONTACT_INFO);
    }
};

export const getDeliveryInfo = (): null | DeliveryInfo => {
    const storedFile = localStorage.getItem(KAF_DELIVERY_INFO);
    if (storedFile) {
        return JSON.parse(storedFile);
    }
    return null;
};

export const storeDeliveryIndo = (deliveryInfo: DeliveryInfo | null) => {
    if (deliveryInfo) {
        localStorage.setItem(KAF_DELIVERY_INFO, JSON.stringify(deliveryInfo));
    } else {
        localStorage.removeItem(KAF_DELIVERY_INFO);
    }
};
