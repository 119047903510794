import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import InterestShowed from "./modules/interests/interest-showed";
import ShowInterest from "./modules/interests/show-interest";
import Kundalini from "./modules/kundalini/kundalini";
import DeleteMyData from "./modules/layout/delete-my-data";
import Home from "./modules/layout/home";
import PageNotFound from "./modules/layout/page-not-found";
import PrivacyPolicy from "./modules/layout/privacy-policy";
import ResponsiveAppBar from "./modules/layout/responsive-app-bar";
import Checkout from "./modules/order/checkout";
import MyOrders from "./modules/order/my-orders";
import OrderDone from "./modules/order/order-done";
import Payment from "./modules/order/payment";
import ServiceDetails from "./modules/order/service-details";
import ServiceListing from "./modules/order/service-listing";
import PaintingDetails from "./modules/showcase/painting-details";
import Showcase from "./modules/showcase/showcase";
import Authorize from "./service/authorize";

const AboutPage = lazy(() => import("./modules/admin/about-page"));
const Admin = lazy(() => import("./modules/admin/admin"));
const GalleryPage = lazy(() => import("./modules/gallery/gallery-page"));

export default (
    <BrowserRouter>
        <ResponsiveAppBar />
        <Routes>
            <Route
                path="*"
                element={
                    <PageNotFound title="Page not found - I guess you are lost somewhere in my website. Better call 911" />
                }
            />
            <Route path="/" element={<Home title="Kishore Kambali - Commission paintings from Kishore Anand" />} />
            <Route
                path="/services"
                element={<ServiceListing title="Services - Commission paintings from Kishore Anand" />}
            />
            <Route path="/authorize" element={<Authorize title="KishoreKambali - Login" />} />
            <Route
                path="/admin"
                element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <Admin title="Admin - If you are not an admin but still can access to this page then I will find you and kick you out" />
                    </Suspense>
                }
            />
            <Route path="/kundalini" element={<Kundalini title="Kundalini - This is a restricted page" />} />
            <Route path="/pay" element={<Payment title="Pay - using UPI id" />} />
            <Route path="/service/:sku" element={<ServiceDetails title="Service details" />} />
            <Route path="/paintings" element={<Showcase title="Showcase of paintings" />} />
            <Route path="/paintings/:id" element={<PaintingDetails title="Service details" />} />
            <Route path="/paintings/:id/buy" element={<ShowInterest />} />
            <Route
                path="/gallery"
                element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <GalleryPage
                            title="Gallery - You can find all my recent paintings here. I bet you will like them"
                            showText
                        />
                    </Suspense>
                }
            />
            <Route
                path="/order/done"
                element={<OrderDone title="Order placed - Thank you! I am sure you will love the painting" />}
            />
            <Route path="/paintings/:id/buy/done" element={<InterestShowed title="Request submitted - Thank you!" />} />
            <Route
                path="/my-orders"
                element={<MyOrders title="My orders - Place as many orders as you can. Take it as a challenge" />}
            />
            <Route
                path="/about"
                element={
                    <Suspense fallback={<div>Loading...</div>}>
                        <AboutPage title="Kishore Anand (@kishorekambali) has a few things to tell about himself" />
                    </Suspense>
                }
            />
            <Route
                path="/privacy"
                element={
                    <PrivacyPolicy title="Privacy policy - I take this seriously. I hope you too. So read every single line from this page." />
                }
            />
            <Route
                path="/checkout"
                element={
                    <Checkout title="Checkout - Thank god you almost there! If you try to go back now, I am going to find you in person" />
                }
            />
            <Route
                path="/delete-my-data"
                element={<DeleteMyData title="Delete my data - I am not sure why on Earth would someone do this" />}
            />
        </Routes>
    </BrowserRouter>
);
