import { useEffect } from "react";
import "./home.scss";
import Profile from "./profile/profile";

interface IProps {
    title: string;
}
const Home = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <div className="home">
            <Profile />

            {/* <AppMessage /> */}
            {/* <BannerServices /> */}
            {/* <BannerGallery /> */}
            {/* <AboutPage title={props.title} hidePageTitle /> */}
            {/* <Paintings title={props.title} /> */}
            {/* <GalleryPage title={props.title} showText={false}/> */}
            {/* <ServiceListing title={props.title} /> */}
        </div>
    );
};

export default Home;
