import FastRewindIcon from "@mui/icons-material/FastRewind";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import InstagramIcon from "@mui/icons-material/Instagram";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../layout/page-title";

interface IProps {
    title: string;
}

const InterestShowed = (props: IProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <Box m={1} p={1} pb={5}>
            <Paper elevation={3}>
                <Box m={1} p={2} maxWidth={400} margin="auto">
                    <PageTitle>Thank you!</PageTitle>
                    <Box marginTop={1} p={0} textAlign="center">
                        <img width={100} src="/global-images/busy.png" alt="kishore anand graphic image" />
                    </Box>
                    <Typography gutterBottom variant="body1" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                        I appreciate your inquiry and am grateful for your interest. Thank you for reaching out. I will
                        review your message and get back to you shortly. I look forward to connecting with you soon to
                        discuss. In the meantime, feel free to reach out if you have any additional questions or
                        concerns.
                        <SentimentSatisfiedAltIcon />
                        <FavoriteBorderIcon />
                    </Typography>
                    <Typography gutterBottom variant="body2" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                        Feel free to direct message me on Instagram
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                        <Button variant="text" startIcon={<FastRewindIcon />} onClick={() => navigate(`/`)}>
                            Home
                        </Button>
                        <Button variant="outlined" startIcon={<InstagramIcon />} href="https://ig.me/m/kishorekambali">
                            Message
                        </Button>
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default InterestShowed;
