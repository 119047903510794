import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import HouseIcon from "@mui/icons-material/House";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import SignpostIcon from "@mui/icons-material/Signpost";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import KeyboardDoubleArrowDown from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import InputAdornment from "@mui/material/InputAdornment";
import { isFieldValid, isPhoneValid, isPinValid } from "../../utils/validations";

export interface DeliveryInfo {
    name: string;
    house: string;
    street: string;
    city: string;
    state: string;
    pin: string;
    phone: string;
}

interface DeliveryInfoProps {
    deliveryInfo: DeliveryInfo | null;
    onDone: (info: DeliveryInfo) => void;
    onBack: () => void;
}

const DeliveryInfoForm = (props: DeliveryInfoProps) => {
    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(true);

    const [house, setHouse] = useState("");
    const [houseValid, setHouseValid] = useState(true);

    const [street, setStreet] = useState("");
    const [streetValid, setStreetValid] = useState(true);

    const [city, setCity] = useState("");
    const [cityValid, setCityValid] = useState(true);

    const [state, setState] = useState("");
    const [stateValid, setStateValid] = useState(true);

    const [pin, setPin] = useState("");
    const [pinValid, setPinValid] = useState(true);

    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);

    useEffect(() => {
        setName(props.deliveryInfo ? props.deliveryInfo.name : "");
        setHouse(props.deliveryInfo ? props.deliveryInfo.house : "");
        setStreet(props.deliveryInfo ? props.deliveryInfo.street : "");
        setCity(props.deliveryInfo ? props.deliveryInfo.city : "");
        setState(props.deliveryInfo ? props.deliveryInfo.state : "");
        setPin(props.deliveryInfo ? props.deliveryInfo.pin : "");
        setPhone(props.deliveryInfo ? props.deliveryInfo.phone : "");
    }, [props.deliveryInfo]);

    const submitDetails = () => {
        const isNameValid = isFieldValid(name);
        setNameValid(isNameValid);

        const isHouseValid = isFieldValid(house);
        setHouseValid(isHouseValid);

        const isStreetValid = isFieldValid(street);
        setStreetValid(isStreetValid);

        const isCityValid = isFieldValid(city);
        setCityValid(isCityValid);

        const isStateValid = isFieldValid(state);
        setStateValid(isStateValid);

        const isThePinValid = isPinValid(pin);
        setPinValid(isThePinValid);

        const isThePhoneValid = isPhoneValid(phone);
        setPhoneValid(isThePhoneValid);

        if (
            !(
                isNameValid &&
                isHouseValid &&
                isStreetValid &&
                isCityValid &&
                isStateValid &&
                isThePinValid &&
                isThePhoneValid
            )
        ) {
            return;
        }
        props.onDone({ name, house, street, city, state, pin, phone });
    };

    const commonSx = {
        width: 300,
    };

    const submitEnabled = () => {
        if (nameValid && houseValid && streetValid && cityValid && stateValid && pinValid && phoneValid) {
            return true;
        }
        return false;
    };

    return (
        <Box m={1} p={1}>
            <Typography gutterBottom variant="h6" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                Shipping address
            </Typography>
            <Stack
                direction="column"
                spacing={{ xs: 2, sm: 2, md: 4 }}
                justifyContent="space-between"
                alignItems="center"
                p={2}
            >
                <TextField
                    label="Name"
                    type="text"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={(e) => setNameValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    error={!nameValid}
                />
                <TextField
                    label="House/Flat/Building"
                    type="text"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HouseIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={house}
                    onChange={(e) => setHouse(e.target.value)}
                    onBlur={(e) => setHouseValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    required
                    error={!houseValid}
                />
                <TextField
                    label="Street/Village/Area"
                    type="text"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SignpostIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    onBlur={(e) => setStreetValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    required
                    error={!streetValid}
                />
                <TextField
                    label="City/District"
                    type="text"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationCityIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    onBlur={(e) => setCityValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    required
                    error={!cityValid}
                />
                <TextField
                    label="State"
                    type="text"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MapIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    onBlur={(e) => setStateValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    required
                    error={!stateValid}
                />
                <TextField
                    label="Pincode"
                    type="number"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PlaceIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    onBlur={(e) => setPinValid(isFieldValid(e.target.value))}
                    sx={commonSx}
                    required
                    error={!pinValid}
                />
                <TextField
                    label="Phone"
                    type="number"
                    variant="outlined"
                    sx={commonSx}
                    required
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                    }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={(e) => setPhoneValid(isPhoneValid(e.target.value))}
                    error={!phoneValid}
                    helperText="Phone number of the receiver"
                />
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                <Button variant="text" onClick={props.onBack} startIcon={<KeyboardDoubleArrowUpIcon />}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowDown />}
                    onClick={submitDetails}
                    disabled={!submitEnabled}
                    color="warning"
                >
                    Continue
                </Button>
            </Stack>
        </Box>
    );
};

export default DeliveryInfoForm;
