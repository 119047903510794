import "./page-title.scss";

interface IProps {}

const PageTitle = (props: React.PropsWithChildren<IProps>) => {
    return (
        <div className="page-title">
            <h1>{props.children}</h1>
        </div>
    );
};

export default PageTitle;
