import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import PageTitle from "../layout/page-title";
import "./payment.scss";

interface IProps {
    title: string;
}
const Payment = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <Box pb={5} px={2} py={{ xs: 2, sm: 3 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Payment</Typography>
            </Breadcrumbs>
            <PageTitle>Payment</PageTitle>
            <Box
                m={1}
                p={2}
                textAlign="center"
                alignSelf="center"
                sx={{
                    background:
                        "linear-gradient(313deg, rgba(255,214,179,1) 0%, rgba(255,250,172,1) 35%, rgba(244,255,175,1) 100%)",
                    borderRadius: 5,
                    maxWidth: "500px",
                    margin: "auto",
                }}
            >
                <Stack direction="column" spacing={2} textAlign="center">
                    <Typography variant="body1">
                        Please consider paying if you want me to start the painting on priority
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        UPI ID:
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        kishore5242@paytm
                    </Typography>
                    {/* <div>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<CurrencyRupeeIcon />}
                                        href={`upi://pay?pa=kishore5242@paytm&pn=Kishore Kambali&cu=INR&am=${
                                            due > 0 ? due : 100
                                        }`}
                                    >
                                        Click to Pay {due > 0 ? due : ""} using UPI
                                    </Button>
                                </div> */}
                    <Box width="100%" display="block">
                        <img
                            className="qr-code"
                            width={250}
                            src="/payment-images/qr-code.png"
                            alt="I upload or timelapse video on my Instagram"
                        />
                    </Box>
                    <Typography gutterBottom variant="body2" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                        Feel free to direct message me on Instagram if you are facing any issues
                    </Typography>
                    <Box m={2} p={0} textAlign="center">
                        <Button variant="outlined" startIcon={<InstagramIcon />} href="https://ig.me/m/kishorekambali">
                            Message
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default Payment;
