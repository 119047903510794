import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Breadcrumbs, Link } from "@mui/material";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { ServiceItem, UploadedFile } from "../../model/order.model";
import {
    getContactInfo,
    getDeliveryInfo,
    getServiceItem,
    getUploadedFile,
    storeContactInfo,
    storeDeliveryIndo,
} from "../../service/local-storage.service";
import PageTitle from "../layout/page-title";
import ContactInfoForm, { ContactInfo } from "./contact-info-form";
import DeliveryInfoForm, { DeliveryInfo } from "./delivery-info-form";
import PhotoUpload from "./photo-upload";
import ServiceItemView from "./service-item";
import Submission from "./submission";
import { charactersAfterUnderscores } from "../util/js-util";
interface IProps {
    title: string;
}
const Checkout = (props: IProps) => {
    const navigate = useNavigate();
    const { loading } = React.useContext(AppContext) as IAppContext;
    const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>();
    const [serviceItem, setServiceItem] = useState<ServiceItem>();
    const [name, setName] = useState("");
    const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
    const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo | null>(null);
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        document.title = props.title;
    }, []);

    // load storedItem from local storage
    useEffect(() => {
        const storedItem = getServiceItem();
        if (storedItem) {
            setServiceItem(storedItem);
        } else {
            navigate("/");
        }
    }, []);

    // load from local storage
    useEffect(() => {
        setUploadedFile(getUploadedFile());
        setContactInfo(getContactInfo());
        setDeliveryInfo(getDeliveryInfo());
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleJump = (to: number) => {
        if (activeStep > to) {
            setActiveStep(to);
        }
    };

    return (
        <Box p={{ xs: 2, sm: 3 }} pb={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/services">
                    Services
                </Link>
                <Link underline="hover" color="inherit" href={`/service/${serviceItem?.sku}`}>
                    {charactersAfterUnderscores(serviceItem?.sku.toLocaleLowerCase())}
                </Link>
                <Typography color="text.primary">Checkout</Typography>
            </Breadcrumbs>
            <PageTitle>Checkout</PageTitle>
            {serviceItem && (
                <Box maxWidth={400} margin="auto" px={1}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key="step-service-edit">
                            <StepLabel onClick={() => handleJump(0)}>
                                <Typography>Confirm service</Typography>
                            </StepLabel>
                            <StepContent>
                                <Box my={2}>
                                    <ServiceItemView service={serviceItem} />
                                </Box>
                                <Box display="flex" justifyContent="space-around" sx={{ mb: 2 }}>
                                    <Button
                                        onClick={() => navigate("/services")}
                                        sx={{ mt: 1, mr: 1 }}
                                        startIcon={<EditTwoToneIcon />}
                                    >
                                        Change
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                        endIcon={<KeyboardDoubleArrowDownIcon />}
                                        color="warning"
                                    >
                                        Confirm
                                    </Button>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step key="step-photo-upload">
                            <StepLabel onClick={() => handleJump(1)}>
                                <Typography>Upload photo</Typography>
                            </StepLabel>
                            <StepContent>
                                <PhotoUpload uploadedFile={uploadedFile} onUpload={(upf) => setUploadedFile(upf)} />
                                <Box display="flex" justifyContent="space-around" sx={{ mb: 2 }}>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                        startIcon={<KeyboardDoubleArrowUpIcon />}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                        disabled={!uploadedFile}
                                        endIcon={<KeyboardDoubleArrowDownIcon />}
                                        color="warning"
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </StepContent>
                        </Step>
                        <Step key="step-contact">
                            <StepLabel onClick={() => handleJump(2)}>
                                <Typography>Contact</Typography>
                            </StepLabel>
                            <StepContent>
                                {uploadedFile && (
                                    <ContactInfoForm
                                        contactInfo={contactInfo}
                                        onDone={(ci) => {
                                            setContactInfo(ci);
                                            storeContactInfo(ci);
                                            handleNext();
                                        }}
                                        onBack={handleBack}
                                    />
                                )}
                            </StepContent>
                        </Step>
                        {uploadedFile && serviceItem.homeDelivery && (
                            <Step key="step-details">
                                <StepLabel onClick={() => handleJump(2)}>
                                    <Typography>Shipping address</Typography>
                                </StepLabel>
                                <StepContent>
                                    <DeliveryInfoForm
                                        deliveryInfo={deliveryInfo}
                                        onDone={(di) => {
                                            setDeliveryInfo(di);
                                            storeDeliveryIndo(di);
                                            handleNext();
                                        }}
                                        onBack={handleBack}
                                    />
                                </StepContent>
                            </Step>
                        )}
                        <Step key="step-submit">
                            <StepLabel onClick={() => handleJump(3)}>
                                <Typography>Submit</Typography>
                            </StepLabel>
                            <StepContent>
                                {uploadedFile && (
                                    <Submission
                                        serviceItem={serviceItem}
                                        uploadedFile={uploadedFile}
                                        contactInfo={contactInfo}
                                        deliveryInfo={deliveryInfo}
                                        onBack={handleBack}
                                    />
                                )}
                            </StepContent>
                        </Step>
                    </Stepper>
                </Box>
            )}
        </Box>
    );
};

export default Checkout;
