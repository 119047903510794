import { useContext, useEffect, useState } from "react";

import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import { Breadcrumbs, InputAdornment, Link } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { db } from "../../firebase";
import { Interest } from "../../model/interest.model";
import { Painting } from "../../model/painting.model";
import { isEmailValid, isFieldValid, isPhoneValid } from "../../utils/validations";

const DEFAULT_PHONE_MESSAGE = "eg. 9034725946";

const ShowInterest = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { paintings } = useContext(AppContext) as IAppContext;
    const [painting, setPainting] = useState<Painting | undefined>();

    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(true);

    const [phone, setPhone] = useState(DEFAULT_PHONE_MESSAGE);
    const [phoneValid, setPhoneValid] = useState(true);

    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (id) {
            const foundPainting = paintings.find((p) => p.id === parseInt(id, 10));
            setPainting(foundPainting);
        }
    }, [paintings]);

    const submitDetails = () => {
        const isNameValid = isFieldValid(name);
        setNameValid(isNameValid);

        const isThePhoneValid = isPhoneValid(phone);
        setPhoneValid(isThePhoneValid);

        const isTheEmailValid = isEmailValid(email);
        setEmailValid(isTheEmailValid);

        if (!(isNameValid && isThePhoneValid && isTheEmailValid)) {
            return;
        }

        setSubmitting(true);

        const interest: Interest = {
            name,
            phone,
            email,
            paintingId: id ? parseInt(id, 10) : 0,
            notes: "Hardcoded notes",
        };

        addDoc(collection(db, "interests"), interest)
            .then(() => {
                sendEmailToKishore(interest)
                    .then(() => sendEmailToPerson())
                    .then(() => navigate(`/paintings/${id}/buy/done`));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const sendEmailToKishore = (interest: Interest) => {
        const mail = {
            to: "kishore.a.social@gmail.com",
            message: {
                subject: `${interest.name} showed interest in your painting!`,
                text: `Name: ${interest.name}, Phone: ${interest.phone}, Email: ${interest.email}, Painting: ${painting?.id}`,
                html: `Name: ${interest.name}<br>Phone: ${interest.phone}<br>Email: ${interest.email}<br>Painting: ${painting?.id}<br><a href="${painting?.photos[0]}">Painting</a>`,
            },
        };

        return addDoc(collection(db, "mail"), mail);
    };

    const sendEmailToPerson = () => {
        const mail = {
            to: email,
            message: {
                subject: `Thank you for showing your interest in the painting!`,
                text: `I will review your request and get back to you shortly. I look forward to connecting with you soon to discuss. Painting id: ${painting?.id} `,
                html: `I will review your request and get back to you shortly. I look forward to connecting with you soon to discuss<br>Painting: ${painting?.id}<br><a href="${painting?.photos[0]}">Painting</a>`,
            },
        };

        return addDoc(collection(db, "mail"), mail);
    };

    const submitEnabled = () => {
        if (submitting) {
            return false;
        }
        if (email && nameValid && phoneValid && emailValid) {
            return true;
        }
        return false;
    };

    const commonSx = {
        width: 300,
    };

    return (
        <Box m={1} p={1} py={{ xs: 2, sm: 3 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/paintings">
                    Paintings
                </Link>
                <Link underline="hover" color="inherit" href={`/paintings/${painting?.id}`}>
                    {painting?.id}
                </Link>
                <Typography color="text.primary">Buy</Typography>
            </Breadcrumbs>
            <Box sx={{ maxWidth: 500, margin: "auto" }}>
                <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    textAlign="center"
                    sx={{ paddingTop: 1 }}
                    color="#ed6c02"
                >
                    Thank you for your interest in my painting!
                </Typography>
                <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                    textAlign="center"
                    sx={{ padding: 1, paddingX: 2 }}
                >
                    To better assist you and discuss your preferences, I kindly request your phone number and email
                    address. This will allow me to reach out to you, answer any questions you may have, and discuss the
                    details of potential art acquisition. Your contact information will be treated with the utmost
                    confidentiality. I look forward to connecting with you soon.
                </Typography>
                <Stack
                    direction="column"
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                >
                    <TextField
                        label="Name"
                        type="text"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setNameValid(isFieldValid(e.target.value));
                        }}
                        onBlur={(e) => setNameValid(isFieldValid(e.target.value))}
                        sx={commonSx}
                        error={!nameValid}
                    />
                    <TextField
                        label="Phone"
                        type="number"
                        variant="outlined"
                        sx={commonSx}
                        required
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                        }}
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneValid(isPhoneValid(e.target.value));
                        }}
                        onBlur={(e) => setPhoneValid(isPhoneValid(e.target.value))}
                        error={!phoneValid}
                        helperText="India phone number"
                    />
                    <TextField
                        label="Email"
                        type="text"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailValid(isEmailValid(e.target.value));
                        }}
                        onBlur={(e) => setEmailValid(isEmailValid(e.target.value))}
                        sx={commonSx}
                        error={!emailValid}
                    />
                </Stack>
                <Typography
                    gutterBottom
                    variant="caption"
                    component="div"
                    textAlign="center"
                    sx={{ padding: 1, paddingX: 2 }}
                >
                    Kindly furnish accurate details; otherwise, I may be unable to contact you. If you don't hear back
                    from me, please reach out via Instagram <a href="https://ig.me/m/kishorekambali">direct message</a>.
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                    <Button variant="text" onClick={() => navigate(`/paintings/${id}`)}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<SendIcon />}
                        onClick={submitDetails}
                        disabled={!submitEnabled()}
                        color="warning"
                    >
                        Submit
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default ShowInterest;
