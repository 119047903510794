import { Breadcrumbs, Link, Skeleton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { Painting } from "../../model/painting.model";
import PageTitle from "../layout/page-title";
import { getThumbnailUrl } from "../util/net-util";
import FiltersInput from "./filters-input";
import "./showcase.scss";

interface IProps {
    title: string;
}

const Showcase = (props: IProps) => {
    const navigate = useNavigate();
    const { loadingPaintings, paintings } = React.useContext(AppContext) as IAppContext;
    const [filters, setFilters] = useState<string[]>([]);
    const [filteredPaintings, setFilteredPaintings] = useState<Painting[]>(paintings);

    useEffect(() => {
        if (props.title) {
            document.title = props.title;
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        if (props.title) {
            document.title = props.title;
        }
    }, [paintings]);

    useEffect(() => {
        if (paintings.length > 0) {
            if (filters.length > 0) {
                const fps = [...paintings.filter((p) => filters.includes(p.category))];
                setFilteredPaintings(fps);
            } else {
                setFilteredPaintings(paintings);
            }
        }
    }, [paintings, filters]);

    const openPainting = (id: number) => {
        navigate(`/paintings/${id}`);
    };

    const onFilterChange = (value: string[]) => {
        setFilters(value);
    };

    return (
        <Box py={{ xs: 2, md: 3 }} margin="auto" className="showcase">
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Paintings</Typography>
            </Breadcrumbs>
            <PageTitle>Showcase</PageTitle>
            <Stack direction="column">
                <div className="search">
                    <FiltersInput paintings={paintings} onValueChange={onFilterChange} />
                </div>

                <div className="images">
                    {loadingPaintings && (
                        <>
                            {[...Array(30)].map((e, i) => (
                                <div className="image-container" key={`skeleton_${i}`}>
                                    <Skeleton
                                        className="image"
                                        key={`card-skeleton-${i}`}
                                        variant="rectangular"
                                        height="100%"
                                        animation="pulse"
                                    />
                                </div>
                            ))}
                        </>
                    )}
                    {filteredPaintings.map((painting: Painting) => (
                        <div
                            className="image-container"
                            key={painting.id}
                            onClick={() => openPainting(painting.id)}
                            tabIndex={0}
                            onKeyDown={() => openPainting(painting.id)}
                        >
                            <img
                                className="image"
                                alt={`${painting.name} by Kishore Anand Kambali (@kishorekambali)`}
                                src={getThumbnailUrl(painting.photos[0])}
                            />
                        </div>
                    ))}
                </div>
            </Stack>
        </Box>
    );
};

export default Showcase;
