import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";
import ReplayTwoToneIcon from "@mui/icons-material/ReplayTwoTone";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { blue } from "@mui/material/colors";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useRef, useState } from "react";
import { storage } from "../../firebase";
import { UploadedFile } from "../../model/order.model";
import { storeUploadedFile } from "../../service/local-storage.service";

import { suffixToFileName } from "../util/file-utils";
import "./order.scss";

interface IProps {
    uploadedFile: UploadedFile | null | undefined;
    index?: number;
    onUpload: (uploadedFile: UploadedFile) => void;
}

const PhotoUpload = (props: IProps) => {
    const [uploading, setUploading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const initScroll = useRef<HTMLDivElement>(null);

    const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setUploading(true);
        const files = e.target.files;
        if (files && files.length > 0) {
            const selectedFile = files[0];

            const imageRef = ref(storage, `paintings/${suffixToFileName(selectedFile.name, Date.now().toString())}`);

            const uploadTask = uploadBytesResumable(imageRef, selectedFile);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100); // update progress
                    setPercentage(percent);
                },
                (err) => {
                    setPercentage(0);
                    setUploading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url): void => {
                        let u = new URL(url);
                        let params = new URLSearchParams(u.search);
                        params.delete("token");
                        u.search = params.toString();
                        const uploadedFile = {
                            fileName: uploadTask.snapshot.ref.name,
                            fileUrl: u.toString(),
                            message: "",
                        };
                        storeUploadedFile(uploadedFile, props.index);
                        props.onUpload(uploadedFile);
                        setUploading(false);
                    });
                }
            );
        } else {
            setUploading(false);
        }
    };

    const renderPhotoArea = () => {
        if (uploading && percentage >= 0) {
            return (
                <Box
                    component="div"
                    sx={{
                        width: 300,
                        height: 400,
                        border: "1px solid",
                        borderColor: blue[200],
                        mt: 2,
                    }}
                    m="auto"
                    p="auto"
                >
                    <Stack direction="column" alignItems="center" spacing={2} height="100%" justifyContent="center">
                        <CircularProgress />
                        {percentage}%
                    </Stack>
                </Box>
            );
        } else if (props.uploadedFile) {
            return (
                <Box
                    component="div"
                    sx={{
                        width: "100%",
                        minWidth: 300,
                        minHeight: 300,
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: "100%",
                            borderRadius: "5px",
                        }}
                        alt="Uploaded photo"
                        src={props.uploadedFile.fileUrl}
                    />
                </Box>
            );
        }
        return (
            <Box
                component="div"
                sx={{
                    width: "100%",
                    minWidth: 300,
                    minHeight: 300,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        width: "100%",
                    }}
                    alt="Sample photo"
                    src="/photo-images/sample_photo_min.jpeg"
                />
            </Box>
        );
    };

    return (
        <>
            <Stack direction="column" py={3} spacing={2} justifyContent="center" alignItems="center">
                <Paper elevation={3} sx={{ maxWidth: 330, width: "100%", minWidth: 300 }} className="old-paper">
                    <Stack direction="column" alignItems="center" spacing={2} justifyContent="center" marginBottom={2}>
                        {renderPhotoArea()}
                        <Button
                            color="warning"
                            variant={props.uploadedFile ? "outlined" : "contained"}
                            component="label"
                            startIcon={props.uploadedFile ? <ReplayTwoToneIcon /> : <ArrowCircleUpTwoToneIcon />}
                            disabled={uploading}
                        >
                            {props.uploadedFile ? "Change" : "Upload"}
                            <input hidden accept="image/*" type="file" onChange={handleOnChange} />
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
            <div ref={initScroll} />
        </>
    );
};

export default PhotoUpload;
