export interface Setting {
    key: string;
    value: string;
}

export interface Content {
    name: string;
    description: string;
    moreInfo: string;
    price: number;
    time: number;
    must: boolean;
    default: boolean;
    value: boolean;
}

export interface ServiceOption {
    name: string;
    code: string;
    contents: Content[];
    full: boolean;
}

export interface Service {
    name: string;
    code: string;
    options: ServiceOption[];
    description: string;
    moreInformation: string;
    background: string;
    full: boolean;
}

export const SettingKey = {
    services: "services",
    order_busy: "order_busy",
    terms_and_conditions: "terms_and_conditions",
    nfbs: "nfbs",
};
