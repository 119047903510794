/**
 * Firebase URLs only
 * @returns
 */
export const suffixUrl = (url: string, suffix: string) => {
    // Split the file name and extension
    const dotIndex = url.lastIndexOf(".");
    const baseName = url.slice(0, dotIndex);
    const extension = url.slice(dotIndex);

    // Concatenate the base name, suffix, and extension
    const newFileName = `${baseName}_${suffix}${extension}`;

    return newFileName;
};

export const getThumbnailUrl = (url: string) => {
    if (url?.includes("firebasestorage")) {
        return suffixUrl(url, "200x200");
    }
    return url;
};

export const isOriginalUrl = (url: string) => {
    if (!url.includes("firebasestorage")) {
        // url is not from firebase
        return true;
    }
    if (!url.includes("200x200")) {
        // url is no a thumbnail
        return true;
    }
    return false;
};
