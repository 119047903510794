import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ServiceItem } from "../../model/order.model";
import "./order.scss";

import { Stack } from "@mui/material";
import { getRandomColor } from "../util/js-util";
import DecoratedPrice from "./decorated-price";

interface IProps {
    service: ServiceItem;
}

export default function ServiceItemView(props: IProps) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/service/${props.service.sku}`);
    };

    return (
        <Card
            sx={{
                display: "flex",
                minHeight: "200px",
                cursor: "pointer",
                background: getRandomColor(props.service.sku),
            }}
            onClick={handleClick}
        >
            <CardMedia
                component="img"
                sx={{ width: 130, minWidth: 130 }}
                image={props.service.images[0]}
                alt={props.service.sku}
            />
            <CardContent>
                <Stack direction="column" spacing={1}>
                    <Typography variant="subtitle2" component="div" color="#616161">
                        <b>{props.service.title}</b>
                    </Typography>
                    <Divider />
                    <Typography variant="body2" color="text.secondary" textAlign="left">
                        <b>Material: </b>
                        {props.service.material}
                    </Typography>
                    <Divider />
                    <DecoratedPrice service={props.service} />
                </Stack>
            </CardContent>
        </Card>
    );
}
