import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./services-board.scss";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Button } from "@mui/material";

interface IProps {
    title: string;
}

const ServicesBoard = (props: IProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.title) {
            document.title = props.title;
        }
    }, []);

    return (
        <Box pt={3} className="services-board">
            <Button
                variant="contained"
                size="small"
                color="warning"
                onClick={() => navigate("/services")}
                sx={{ margin: "auto" }}
                endIcon={<DoubleArrowIcon />}
            >
                Commission now
            </Button>
        </Box>
    );
};

export default ServicesBoard;
