import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InstagramIcon from "@mui/icons-material/Instagram";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Breadcrumbs, Button, InputAdornment, Link, Paper, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useEffect, useState } from "react";
import { Order } from "../../model/order.model";
import { formatPrice } from "../../utils/calculations";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";

import { collection, getDocs, query, where } from "firebase/firestore";
import { isFieldValid, isPhoneValid } from "../../utils/validations";

import SearchIcon from "@mui/icons-material/Search";

import { db } from "../../firebase";
import { getContactInfo } from "../../service/local-storage.service";
import { Divider } from "antd";
import PageTitle from "../layout/page-title";
import { getThumbnailUrl } from "../util/net-util";

interface IProps {
    title: string;
}
const DEFAULT_PHONE_MESSAGE = "eg. 9465836508";

const MyOrders = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    const [tabValue, setTabValue] = useState(0);

    const [phone, setPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(true);
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [phoneMessage, setPhoneMessage] = useState(DEFAULT_PHONE_MESSAGE);

    const [orderId, setOrderId] = useState("");
    const [orderIdValid, setOrderIdValid] = useState(true);
    const [orderIdTouched, setOrderIdTouched] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<Order[]>([]);
    const [amountDue, setAmountDue] = useState<number>(0);
    const [expanded, setExpanded] = useState<string | null>(null);

    useEffect(() => {
        const contactInfo = getContactInfo();
        if (contactInfo) {
            setPhone(contactInfo.userId);
        }
    }, []);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredPhone = e.target.value;
        setPhone(enteredPhone);
        if (phoneTouched) validatePhone(enteredPhone);
    };

    const handlePhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const enteredPhone = e.target.value;
        setPhoneTouched(true);
        validatePhone(enteredPhone);
    };

    const validatePhone = (p: string) => {
        const isThePhoneValid = isPhoneValid(p);
        if (!isThePhoneValid) {
            setPhoneValid(false);
            setPhoneMessage("Invalid phone number");
        } else {
            setPhoneValid(true);
            setPhoneMessage(DEFAULT_PHONE_MESSAGE);
        }
        return isThePhoneValid;
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSubmitted(false);
        setTabValue(newValue);
    };

    const trackByContact = () => {
        // validate each fields
        const isUserIdValid = validatePhone(phone);

        // validate the submit
        if (!isUserIdValid) return;

        setLoading(true);
        const q = query(collection(db, "orders"), where("contact", "==", phone));
        getDocs(q).then((querySnapshot) => {
            const myOrders: Order[] = [];
            querySnapshot.forEach((doc) => {
                myOrders.push(doc.data() as Order);
            });
            setOrders(myOrders);
            setLoading(false);
            setAmountDue(calculateTotalAmountDue(myOrders));
            setSubmitted(true);
        });
    };

    const trackByOrderId = () => {
        // validate each fields
        const isOrderIdValid = isFieldValid(orderId);
        setOrderIdValid(isOrderIdValid);

        // validate the submit
        if (!isOrderIdValid) return;

        setLoading(true);
        const q = query(collection(db, "orders"), where("id", "==", Number.parseInt(orderId)));
        getDocs(q).then((querySnapshot) => {
            const myOrders: Order[] = [];
            querySnapshot.forEach((doc) => {
                myOrders.push(doc.data() as Order);
            });
            setOrders(myOrders);
            setLoading(false);
            setAmountDue(calculateTotalAmountDue(myOrders));
            setSubmitted(true);
        });
    };

    const handleChange = (panel: string) => {
        setExpanded(expanded === panel ? null : panel);
    };

    const ordersComparator = (a: Order, b: Order) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
    };

    // calculate total amount due
    const calculateTotalAmountDue = (orders: Order[]) => {
        let sum = 0;
        orders.forEach((order) => {
            if (order.paymentStatus.toUpperCase() === "PENDING") {
                const p = order.price;
                if (p) {
                    sum = sum + p;
                }
            }
        });
        return sum;
    };

    return (
        <Box p={1} pb={3} py={{ xs: 2, sm: 3 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">My orders</Typography>
            </Breadcrumbs>
            <PageTitle>My orders</PageTitle>
            <TabContext value={tabValue.toString()}>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                    <Tab label="Track by Phone" />
                    <Tab label="Track by Order ID" />
                </Tabs>
                <TabPanel value="0">
                    <Box m={1} p={1}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                            textAlign="center"
                            sx={{ paddingTop: 2 }}
                        >
                            Enter your phone number
                        </Typography>
                        <Stack
                            direction="column"
                            spacing={{ xs: 2, sm: 2, md: 4 }}
                            justifyContent="space-between"
                            alignItems="center"
                            p={2}
                        >
                            <TextField
                                id="outlined-helperText"
                                label="Phone"
                                type="text"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                }}
                                value={phone}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneBlur}
                                required
                                helperText={phoneMessage}
                                error={!phoneValid}
                            />
                        </Stack>
                        <Typography
                            gutterBottom
                            variant="caption"
                            component="div"
                            textAlign="center"
                            sx={{ padding: 1, paddingX: 2 }}
                        >
                            Note: This should be same as what was given while placing the orders
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                            <Button
                                variant="contained"
                                endIcon={<SearchIcon />}
                                onClick={trackByContact}
                                disabled={!phoneValid}
                                color="warning"
                                size="large"
                            >
                                Track
                            </Button>
                        </Stack>
                    </Box>
                </TabPanel>
                <TabPanel value="1">
                    <Box m={1} p={1}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                            textAlign="center"
                            sx={{ paddingTop: 2 }}
                        >
                            Enter your Order ID
                        </Typography>
                        <Stack
                            direction="column"
                            spacing={{ xs: 2, sm: 2, md: 4 }}
                            justifyContent="space-between"
                            alignItems="center"
                            p={2}
                        >
                            <TextField
                                id="outlined-helperText"
                                label="Order ID"
                                type="text"
                                variant="outlined"
                                value={orderId}
                                onChange={(e) => {
                                    setSubmitted(false);
                                    setOrderId(e.target.value?.trim());
                                    if (orderIdTouched) setOrderIdValid(isFieldValid(e.target.value));
                                }}
                                onBlur={(e) => {
                                    setOrderIdTouched(true);
                                    setOrderIdValid(isFieldValid(e.target.value));
                                }}
                                required
                                helperText="eg. 1234567"
                                error={!orderIdValid}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="space-around" p={2}>
                            <Button
                                variant="contained"
                                endIcon={<SearchIcon />}
                                onClick={trackByOrderId}
                                disabled={!orderIdValid}
                                color="warning"
                                size="large"
                            >
                                Track
                            </Button>
                        </Stack>
                    </Box>
                </TabPanel>
            </TabContext>
            {loading ? (
                <Skeleton
                    key="od-skeleton-1"
                    variant="rectangular"
                    width="100%"
                    height={50}
                    sx={{ margin: "auto" }}
                    animation="wave"
                />
            ) : (
                <>
                    {submitted &&
                        orders &&
                        orders.sort(ordersComparator).map((order, oi) => (
                            <Accordion
                                key={`accordion_${oi}`}
                                expanded={expanded === order.id.toString()}
                                onChange={() => handleChange(order.id.toString())}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        backgroundColor: `${expanded === order.id.toString() ? "#fff3e0" : "#fffde7"}`,
                                    }}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Stack direction="row" spacing={1}>
                                        <Box
                                            component="img"
                                            sx={{
                                                // width: "100%",
                                                maxWidth: "100px",
                                                borderRadius: "5px",
                                            }}
                                            alt="Uploaded photo"
                                            src={getThumbnailUrl(order.photo)}
                                        />
                                        <Stack direction="column">
                                            <Typography>
                                                Order ID: <b>{order.id}</b>
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "text.secondary",
                                                    flex: 1,
                                                }}
                                            >
                                                Status: {order.status}
                                            </Typography>
                                            {order.paymentStatus === "Pending" && (
                                                <Typography
                                                    sx={{
                                                        color: "red",
                                                        flex: 1,
                                                    }}
                                                >
                                                    Payment is pending. Painting will be started only after payment is
                                                    confirmed.
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table" sx={{ tableLayout: "fixed" }}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ordered ID
                                                    </TableCell>
                                                    <TableCell align="right">{order.id}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Service
                                                    </TableCell>
                                                    <TableCell align="right">{order.service}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Ordered by
                                                    </TableCell>
                                                    <TableCell align="right">{order.contact}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Photo
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ position: "relative" }}>
                                                        <Button
                                                            variant="contained"
                                                            color="warning"
                                                            onClick={() => window.open(order.photo, "_blank")}
                                                            sx={{
                                                                position: "absolute",
                                                                top: "20px",
                                                                right: "20px",
                                                                width: "38px",
                                                                minWidth: "unset",
                                                                padding: "4px",
                                                            }}
                                                        >
                                                            <OpenInNewIcon />
                                                        </Button>

                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                width: "100%",
                                                                maxWidth: "100px",
                                                                borderRadius: "5px",
                                                            }}
                                                            alt="Uploaded photo"
                                                            src={getThumbnailUrl(order.photo)}
                                                            onClick={() => window.open(order.photo, "_blank")}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Order time
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {order.orderTime
                                                            ? moment.unix(order.orderTime).format("DD/MM/YYYY HH:mm:ss")
                                                            : ""}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Payment status
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    order.paymentStatus === "Pending" ? "red" : ""
                                                                }`,
                                                            }}
                                                        >
                                                            {order.paymentStatus}
                                                        </span>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Option
                                                    </TableCell>
                                                    <TableCell align="right">{order.option}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Instagram Post
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {order.instagramPost ? "YES" : "NO"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Home delivery
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {order.homeDelivery ? "YES" : "NO"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Price
                                                    </TableCell>
                                                    <TableCell align="right">{formatPrice(order.price)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Shipping address
                                                    </TableCell>
                                                    <TableCell align="right">{order.shippingAddress}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        Phone of receiver
                                                    </TableCell>
                                                    <TableCell align="right">{order.phone}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    {submitted && orders.length > 0 && (
                        <Stack direction="column" spacing={2} textAlign="center" my={5} mx={2}>
                            <Typography variant="h5" fontWeight="bold">
                                Payment
                            </Typography>
                            <Divider />
                            <Typography variant="body1" fontWeight="bold">
                                Your total amount due is{" "}
                                <span style={{ color: `${amountDue > 0 ? "red" : ""}` }}>₹{amountDue}</span>
                            </Typography>
                            <Typography variant="body2">
                                Please use the below UPI ID to make payments or donations. I will take 30min to confirm
                                any transactions.
                            </Typography>
                            <Typography variant="body2">UPI ID:</Typography>
                            <Typography variant="h6" fontWeight="bold">
                                kishore5242@paytm
                            </Typography>
                            <Typography variant="h6" fontWeight="bold">
                                Ph. no. 9035367148
                            </Typography>
                            <Box width="100%" display="block">
                                <img
                                    className="qr-code"
                                    width={250}
                                    src="/payment-images/qr-code.png"
                                    alt="I upload or timelapse video on my Instagram"
                                />
                            </Box>
                        </Stack>
                    )}
                    {submitted && orders.length === 0 && (
                        <Stack direction="column" textAlign="center">
                            <Box width="100%" display="flex" minHeight="250px">
                                <img
                                    width={250}
                                    src="/global-images/empty.png"
                                    alt="No orders"
                                    style={{ margin: "auto" }}
                                />
                            </Box>
                            <Typography variant="h6" fontWeight="bold">
                                No orders found for the given details
                            </Typography>
                        </Stack>
                    )}
                    <Stack direction="column" spacing={2} textAlign="center" my={5} mx={2}>
                        <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            textAlign="center"
                            sx={{ paddingTop: 2 }}
                        >
                            Feel free to direct message me on Instagram if you are facing any issues
                        </Typography>
                        <Box m={2} p={0} textAlign="center">
                            <Button
                                variant="outlined"
                                startIcon={<InstagramIcon />}
                                href="https://ig.me/m/kishorekambali"
                            >
                                Message
                            </Button>
                        </Box>
                    </Stack>
                </>
            )}
        </Box>
    );
};

export default MyOrders;
