import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import Goals from "./goals";

interface IProps {
    title: string;
}
const Kundalini = (props: IProps) => {
    const [value, setValue] = useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Box py={1} width="100%">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Goals" value="1" />
                        <Tab label="Progress" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Goals title={props.title} />
                </TabPanel>
                <TabPanel value="2">Progress</TabPanel>
            </TabContext>
        </Box>
    );
};

export default Kundalini;
