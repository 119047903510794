import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

interface IProps {
    title: string;
}

const DeleteMyData = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <Box p={1} display="flex" justifyContent="center" alignItems="center">
            <Paper elevation={3}>
                <Stack spacing={2} direction="column" justifyContent="space-around" alignItems="left" p={5}>
                    <Typography variant="h6">How to delete your personal data</Typography>
                    <Typography variant="body1">
                        If you wish to erase all your personal data that we have stored ( that includes - login details,
                        uploaded photos, Shipping addresses, Email, Phone number), Please click the link below. Your
                        active orders will be cancelled.
                    </Typography>
                    <Typography variant="h6">
                        <a href="/delete">Logout and delete the account</a>
                    </Typography>
                </Stack>
            </Paper>
        </Box>
    );
};

export default DeleteMyData;
