import { Box, Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";

import { collection, onSnapshot } from "firebase/firestore";

import { db } from "../../firebase";
import { Goal, KundaliniJson, KundaliniUser } from "./models";
import { getTodaysMark } from "./utils";

interface IProps {
    title: string;
}

const Goals = (props: IProps) => {
    const collectionKundalini = collection(db, "kundalini");
    const [loading, setLoading] = useState(true);
    const [kundaliniUsers, setKundaliniUsers] = useState<KundaliniUser[]>([]);
    const [kundaliniJson, setKundaliniJson] = useState<KundaliniJson | null>(null);

    const currentChakra = kundaliniJson?.chakras.find((c) => c.name === kundaliniJson.status);

    useEffect(() => {
        document.title = props.title;
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(collectionKundalini, (querySnapshot) => {
            const items: KundaliniUser[] = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data() as KundaliniUser);
            });
            setKundaliniUsers(items);

            // current user
            const kJson = JSON.parse(items[0].value) as KundaliniJson;
            setKundaliniJson(kJson);

            setLoading(false);
        });
        return () => {
            unsub();
        };
    }, []);

    const hasTodaysMark = (goal: Goal) => {
        return goal.markings.includes(getTodaysMark());
    };

    const mark = (goal: Goal, value: boolean) => {
        const goalToUpdate = currentChakra?.goals.find((g) => g.name === goal.name);
        const todaysMark = getTodaysMark();
        if (value && !hasTodaysMark(goal)) {
            goalToUpdate?.markings.push(todaysMark);
        } else if (goalToUpdate && !value && hasTodaysMark(goal)) {
            const myArray = goalToUpdate?.markings;
            const newArray = myArray?.filter((element) => element !== todaysMark);
            goalToUpdate.markings = newArray;
        }
        setKundaliniJson(JSON.parse(JSON.stringify(kundaliniJson)));
    };

    const onSubmit = () => {
        console.log(kundaliniJson);
    };

    return (
        <Box p={0} paddingTop={3} pb={5}>
            {loading ? (
                <Skeleton
                    key="od-skeleton-1"
                    variant="rectangular"
                    width="100%"
                    height={50}
                    sx={{ margin: "auto" }}
                    animation="wave"
                />
            ) : (
                <>
                    <FormGroup>
                        {currentChakra?.goals.map((goal, gi) => (
                            <FormControlLabel
                                sx={{
                                    margin: "0 0 10px 0",
                                    borderRadius: "5px",
                                    backgroundColor: "rgb(245, 155, 66, 0.3)",
                                }}
                                control={
                                    <Checkbox
                                        checked={hasTodaysMark(goal)}
                                        onChange={(e) => mark(goal, e.target.checked)}
                                    />
                                }
                                label={goal.description}
                            />
                        ))}
                    </FormGroup>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ width: "100%", marginTop: "10px" }}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </>
            )}
        </Box>
    );
};

export default Goals;
