import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import KeyboardDoubleArrowUp from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { addDoc, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { db } from "../../firebase";
import { SettingKey } from "../../model/admin.model";
import { Order, OrderStatus, PaymentStatus, ServiceItem, UploadedFile } from "../../model/order.model";
import { storeUploadedFile } from "../../service/local-storage.service";
import { formatPrice, formattedDeliveryAddress } from "../../utils/calculations";
import { ContactInfo } from "./contact-info-form";
import { DeliveryInfo } from "./delivery-info-form";
import { getThumbnailUrl } from "../util/net-util";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface SubmissionProps {
    serviceItem: ServiceItem;
    uploadedFile: UploadedFile;
    contactInfo: ContactInfo | null;
    deliveryInfo: DeliveryInfo | null;
    onBack: () => void;
}

const Submission = (props: SubmissionProps) => {
    const { settings } = React.useContext(AppContext) as IAppContext;
    const navigate = useNavigate();
    const [terms, setTerms] = useState("");
    const [agreed, setAgreed] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // refresh services
    useEffect(() => {
        const setting = settings.find((s) => s.key === SettingKey.terms_and_conditions);
        if (setting?.value) {
            setTerms(setting.value);
        }
    }, [settings]);

    const onAgree = (did: boolean) => {
        setAgreed(did);
    };

    const onSubmit = () => {
        setSubmitting(true);

        const order: Order = {
            id: Math.round(Date.now() / 1000),
            contact: props.contactInfo ? props.contactInfo.userId : "",
            sku: props.serviceItem.sku,
            status: OrderStatus.submitted,
            paymentStatus: PaymentStatus.pending,
            orderTime: Math.round(Date.now() / 1000),
            service: props.serviceItem.title,
            option: props.serviceItem.material,
            instagramPost: props.serviceItem.timelapseVideo,
            homeDelivery: props.serviceItem.homeDelivery,
            price: props.serviceItem.price,
            shippingAddress: formattedDeliveryAddress(props.deliveryInfo),
            phone: props.deliveryInfo ? props.deliveryInfo.phone.toString() : "",
            photo: props.uploadedFile.fileUrl,
        };

        addDoc(collection(db, "orders"), order)
            .then(() => {
                sendNewOrderEmail(order).then(() => navigate("/order/done", { state: { order } }));
            })
            .finally(() => {
                setSubmitting(false);
                storeUploadedFile(null);
            });
    };

    const sendNewOrderEmail = (order: Order) => {
        const mail = {
            to: "kishore.a.social@gmail.com",
            message: {
                subject: "You have a new Order!",
                text: `Order id: ${order.id}, By: ${order.contact}, SKU: ${order.sku}, Price: ${order.price}, Photo: ${order.photo}`,
                html: `Order id: ${order.id}<br>By: ${order.contact}<br>SKU: ${order.sku}<br>Price: ${order.price}<br><a href="${order.photo}">Uploaded photo</a>`,
            },
        };

        return addDoc(collection(db, "mail"), mail);
    };

    return (
        <Box>
            <Typography gutterBottom variant="h6" component="div" textAlign="center">
                Confirm details
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ width: "320px" }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Service
                            </TableCell>
                            <TableCell align="right">{props.serviceItem.title}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Material
                            </TableCell>
                            <TableCell align="right">{props.serviceItem.material}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Timelapse video
                            </TableCell>
                            <TableCell align="right">{props.serviceItem.timelapseVideo ? "Yes" : "No"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Home delivery
                            </TableCell>
                            <TableCell align="right">{props.serviceItem.homeDelivery ? "Yes" : "No"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Photo
                            </TableCell>
                            <TableCell align="right" sx={{ position: "relative" }}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => window.open(props.uploadedFile.fileUrl, "_blank")}
                                    sx={{
                                        position: "absolute",
                                        top: "20px",
                                        right: "20px",
                                        width: "38px",
                                        minWidth: "unset",
                                        padding: "4px",
                                    }}
                                >
                                    <OpenInNewIcon />
                                </Button>
                                <Box
                                    component="img"
                                    sx={{
                                        width: "100%",
                                        maxWidth: "100px",
                                        borderRadius: "5px",
                                    }}
                                    alt="Uploaded photo"
                                    src={getThumbnailUrl(props.uploadedFile.fileUrl)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Contact
                            </TableCell>
                            <TableCell align="right">{props.contactInfo ? props.contactInfo.userId : ""}</TableCell>
                        </TableRow>
                        {props.serviceItem.homeDelivery && (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Delivery address
                                </TableCell>
                                <TableCell align="right">{formattedDeliveryAddress(props.deliveryInfo)} </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Total price
                            </TableCell>
                            <TableCell align="right">
                                <b>{formatPrice(props.serviceItem.price)}</b>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={1} my={2} sx={{ border: "1px dashed grey", borderRadius: "5px", color: "#331509" }}>
                <Typography variant="subtitle1" textAlign="center">
                    Terms and conditions
                </Typography>
                <Typography variant="body2">{terms}</Typography>
                <FormControlLabel
                    control={<Checkbox checked={agreed} onChange={(e) => onAgree(e.target.checked)} />}
                    label={
                        <Typography variant="body2" color={agreed ? "green" : "#c74c00"}>
                            I agree to the Terms and Conditions
                        </Typography>
                    }
                />
            </Box>
            <Stack direction="row" spacing={2} justifyContent="space-around" py={4}>
                <Button variant="text" onClick={props.onBack} startIcon={<KeyboardDoubleArrowUp />}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<CheckCircleOutline />}
                    onClick={onSubmit}
                    disabled={!agreed || submitting}
                >
                    Submit
                </Button>
            </Stack>
        </Box>
    );
};

export default Submission;
