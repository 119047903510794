import { ICurrentUser } from "../model/auth.model";
import { OrderItem } from "../model/order.model";
import { DeliveryInfo } from "../modules/order/delivery-info-form";

export const calculatePrice = (orderItem: OrderItem) => {
    let price = 0;
    orderItem.serviceOption.contents.forEach((c) => {
        if (c.value) {
            price = price + c.price;
        }
    });
    return price;
};

export const formatPrice = (price: number) => {
    if (price <= 0) {
        return "FREE";
    } else {
        return `₹${price}`;
    }
};

export const isInstagramPostNeeded = (orderItem: OrderItem) => {
    let needed = false;
    orderItem.serviceOption.contents.forEach((c) => {
        if (c.name === "Instagram post") {
            needed = c.value;
        }
    });
    return needed;
};

export const isDeliveryNeeded = (orderItem: OrderItem) => {
    let needed = false;
    orderItem.serviceOption.contents.forEach((c) => {
        if (c.name === "Home delivery") {
            needed = c.value;
        }
    });
    return needed;
};

export const formattedCurrentUser = (currentUser: ICurrentUser) => {
    return `${currentUser.name}, ${currentUser.email}`;
};

export const formattedDeliveryAddress = (deliveryInfo: DeliveryInfo | null) => {
    if (!deliveryInfo) {
        return "NA";
    }
    return `${deliveryInfo.name}, ${deliveryInfo.house}, ${deliveryInfo.street}, ${deliveryInfo.city}, ${deliveryInfo.state}, ${deliveryInfo.pin}, Ph. ${deliveryInfo.phone}`;
};
