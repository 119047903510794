export function charactersAfterUnderscores(inputString?: string) {
    if (!inputString) return "";

    // Split the input string by underscores
    const parts = inputString.split("_");

    // Filter out empty strings resulting from consecutive underscores
    const filteredParts = parts.filter((part) => part !== "");

    // Extract the first character of each non-empty part after the first underscore
    const result = filteredParts
        .slice(1)
        .map((part) => part[0])
        .join("");

    return result;
}

export const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return function (...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export function getRandomStringFromArray(array: any[]) {
    // Check if the array is not empty
    if (array.length === 0) {
        return null; // Return null or handle the case as needed
    }

    // Generate a random index within the array length
    const randomIndex = Math.floor(Math.random() * array.length);

    // Return the randomly selected string
    return array[randomIndex];
}

export function hashString(str: string) {
    // A simple hash function (you might want to use a more robust hashing algorithm)
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32-bit integer
    }
    return hash;
}

export function getValueBasedOnHash(array: any[], str: string) {
    if (array.length === 0) {
        return null; // Return null or handle the case as needed
    }

    // Calculate the hash code based on the input string
    const hashCode = hashString(str);

    // Use modulo to get a valid index within the array length
    const index = Math.abs(hashCode) % array.length;

    // Return the value at the calculated index
    return array[index];
}

export function getRandomColor(key?: string) {
    const palette = ["#ffdbb8", "#bde7ff", "#9db8eb", "#fdffb3", "#a8eb9d", "ECF2FF", "#EEEEEE", "FFE5F1"];
    if (key) {
        return getValueBasedOnHash(palette, key);
    }
    return getRandomStringFromArray(palette);
}

export function getRandomGradiantColor(key?: string) {
    const palette = [
        "linear-gradient(90deg, rgba(255,238,186,0.5) 0%, rgba(255,212,100,0.5) 35%, rgba(255,207,155,0.5) 100%)",
        "linear-gradient(90deg, rgba(252,255,186,0.5) 0%, rgba(226,255,100,0.5) 35%, rgba(209,255,155,0.5) 100%)",
        "linear-gradient(90deg, rgba(186,255,252,0.5) 0%, rgba(100,255,154,0.5) 35%, rgba(155,255,212,0.5) 100%)",
        "linear-gradient(90deg, rgba(186,223,255,0.5) 0%, rgba(100,160,255,0.5) 35%, rgba(188,155,255,0.5) 100%)",
        "linear-gradient(90deg, rgba(255,186,220,0.5) 0%, rgba(212,100,255,0.5) 35%, rgba(247,155,255,0.5) 100%)",
        "linear-gradient(90deg, rgba(255,186,194,0.5) 0%, rgba(255,131,100,0.5) 35%, rgba(255,227,155,0.5) 100%)",
        "linear-gradient(90deg, rgba(186,255,214,0.5) 0%, rgba(100,255,245,0.5) 35%, rgba(255,250,155,0.5) 100%)",
        "linear-gradient(90deg, rgba(255,186,221,0.5) 0%, rgba(100,181,255,0.5) 35%, rgba(155,255,177,0.5) 100%)",
    ];

    if (key) {
        return getValueBasedOnHash(palette, key);
    }
    return getRandomStringFromArray(palette);
}
