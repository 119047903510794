export const isEmailValid = (email: string) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
        return true;
    } else {
        return false;
    }
};

export const isPhoneValid = (phone: string) => {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(phone);
};

export const isPinValid = (pin: string) => {
    return pin.length >= 5;
};

export const isFieldValid = (field: string) => {
    return !!field;
};
