import { useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import LoginBasic from "./login-basic";
interface IProps {
    title: string;
}

const Authorize = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <Box m={1} pt={5}>
            <Stack
                spacing={2}
                direction={{ xs: "column", md: "row" }}
                justifyContent="space-around"
                alignItems="center"
                divider={<span>Or</span>}
            >
                <LoginBasic />
            </Stack>
        </Box>
    );
};

export default Authorize;
