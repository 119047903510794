import { Service, ServiceOption } from "./admin.model";

export interface UploadedFile {
    fileName: string;
    fileUrl: string;
    message: string;
}

export interface OrderDetail {
    name: string;
    value: string;
}

export interface Order {
    id: number;
    contact: string;
    sku: string;
    status: string;
    paymentStatus: string;
    orderTime: number;
    service: string;
    option: string;
    instagramPost: boolean;
    homeDelivery: boolean;
    price: number;
    shippingAddress: string;
    phone: string;
    photo: string;
    dbId?: string;
}

export interface OrderItem {
    service: Service;
    serviceOption: ServiceOption;
}

export interface ServiceItem {
    sku: string;
    title: string;
    description: string;
    timelapseVideo: boolean;
    homeDelivery: boolean;
    closed: string;
    material: string;
    videoIn: number;
    deliveryIn: number;
    price: number;
    actualPrice?: number;
    images: string[];
}

export const OrderStatus = {
    submitted: "Submitted",
    painting_in_progress: "Painting in progress",
    painting_completed: "Painting completed",
    painting_packed: "Painting packed",
    in_transit: "In transit",
    completed: "Completed",
    cancelled: "Cancelled"
};

export const PaymentStatus = {
    na: "NA",
    pending: "Pending",
    paid: "Paid",
};
