import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect } from "react";
import AppContext, { IAppContext } from "../../contexts/app-context";
import PageTitle from "../layout/page-title";
import ServiceItemModern from "./service-item-modern";
import { Breadcrumbs, Link, Typography } from "@mui/material";

interface IProps {
    title: string;
}

const ServiceListing = (props: IProps) => {
    const { services } = React.useContext(AppContext) as IAppContext;
    useEffect(() => {
        document.title = props.title;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    // sort on price by default
    services.sort((s1, s2) => {
        if (s1.closed) {
            return 1;
        } else if (s2.closed) {
            return -1;
        }
        return s1.price - s2.price;
    });

    return (
        <Box m={1} pb={2} mb={10} px={1} py={{ xs: 1, sm: 2 }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Services</Typography>
            </Breadcrumbs>
            <PageTitle>Services</PageTitle>
            <Grid container spacing={2}>
                {services.map((service) => (
                    <Grid item xs={12} md={6} lg={4} key={service.sku + "_grid"}>
                        <ServiceItemModern key={service.sku} service={service} />
                    </Grid>
                ))}
                {services.length <= 0 && (
                    <>
                        <Grid item xs={12} md={6} lg={4} key="loading_grid1">
                            <Skeleton
                                key="card-skeleton-1"
                                variant="rectangular"
                                width="100%"
                                height={200}
                                sx={{ margin: "auto" }}
                                animation="wave"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} key="loading_grid2">
                            <Skeleton
                                key="card-skeleton-2"
                                variant="rectangular"
                                width="100%"
                                height={200}
                                sx={{ margin: "auto" }}
                                animation="wave"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} key="loading_grid3">
                            <Skeleton
                                key="card-skeleton-3"
                                variant="rectangular"
                                width="100%"
                                height={200}
                                sx={{ margin: "auto" }}
                                animation="wave"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default ServiceListing;
