import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Carousel } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceItem } from "../../model/order.model";
import { formatPrice } from "../../utils/calculations";
import "./order.scss";

import { Box, Breadcrumbs, Link } from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { storeServiceItem } from "../../service/local-storage.service";
import DecoratedPrice from "./decorated-price";
import { charactersAfterUnderscores } from "../util/js-util";

interface IProps {
    title: string;
}

export default function ServiceDetails(props: IProps) {
    const { services } = useContext(AppContext) as IAppContext;
    const [service, setService] = useState<ServiceItem | undefined>();
    const navigate = useNavigate();

    const { sku } = useParams();

    useEffect(() => {
        document.title = props.title;
    }, []);

    useEffect(() => {
        const foundService = services.find((ser) => ser.sku === sku);
        setService(foundService);
    }, [services]);

    const handleOrder = () => {
        storeServiceItem(service);
        navigate("/checkout");
    };

    

    return (
        <Box px={1} py={{ xs: 2, sm: 3 }} pb={6}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/services">
                    Services
                </Link>
                <Typography color="text.primary">{charactersAfterUnderscores(service?.sku.toLocaleLowerCase())}</Typography>
            </Breadcrumbs>
            {service && (
                <Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={2} mt={0}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Box maxWidth={400}>
                            <Carousel autoplay autoplaySpeed={2000}>
                                {service.images.map((image: string, i: number) => (
                                    <Box
                                        key={`${service.sku}_image_${i}`}
                                        component="img"
                                        sx={{
                                            minWidth: "400px",
                                        }}
                                        alt={`painting by kishore anand (@kishorekambali)`}
                                        src={image}
                                    />
                                ))}
                            </Carousel>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8} display="flex" justifyContent="center" alignItems="center">
                        <Stack spacing={2} p={2} maxWidth={500}>
                            <Typography variant="h6" fontWeight={800} color="text.secondary">
                                {service.title}
                            </Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" textAlign="left">
                                <b>Material: </b>
                                {service.material}
                            </Typography>
                            {service.homeDelivery && (
                                <Typography variant="body1" color="text.secondary" textAlign="left">
                                    <b>{"Delivery in: "} </b>
                                    {service.deliveryIn}
                                    {" days"}
                                </Typography>
                            )}
                            {service.timelapseVideo && (
                                <Typography variant="body1" color="text.secondary" textAlign="left">
                                    <b>{"Video in: "} </b>
                                    {service.videoIn}
                                    {" days"}
                                </Typography>
                            )}
                            {service.closed && (
                                <Typography variant="body1" color="#ff5722" textAlign="left">
                                    {service.closed}
                                </Typography>
                            )}
                            <Divider />
                            <Stack direction="row" justifyContent="space-between">
                                {/* <Typography variant="h5" sx={{paddingTop: 1}}>
                                    {formatPrice(service.price)}
                                </Typography> */}
                                <DecoratedPrice service={service} />
                                <Button
                                    variant="contained"
                                    color="warning"
                                    endIcon={<ShoppingBagTwoToneIcon />}
                                    onClick={handleOrder}
                                    size="large"
                                    disabled={!!service.closed}
                                    sx={{ width: 200 }}
                                >
                                    Commission
                                </Button>
                            </Stack>

                            <Divider />
                            <Typography variant="h6" color="text.secondary">
                                Description:
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {service.description}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
