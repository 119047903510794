import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import InstagramIcon from "@mui/icons-material/Instagram";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { SettingKey } from "../../model/admin.model";
import { OrderItem, UploadedFile } from "../../model/order.model";
import { getOrderItem, getUploadedFile, storeUploadedFile } from "../../service/local-storage.service";
import { calculatePrice } from "../../utils/calculations";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

interface IProps {
    title: string;
}
const OrderDone = (props: IProps) => {
    const { settings } = React.useContext(AppContext) as IAppContext;
    const [orderedItem, setOrderedItem] = useState<OrderItem | null>();
    const [busyMessage, setBusyMessage] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = props.title;
    }, []);

    useEffect(() => {
        setOrderedItem(getOrderItem());
        storeUploadedFile(null);
    }, []);

    useEffect(() => {
        const busySetting = settings.find((s) => s.key === SettingKey.order_busy);
        setBusyMessage(busySetting ? busySetting.value : "");
    }, [settings]);

    const renderPayMsg = () => {
        return "paying in advance";

        // if (orderedItem) {
        //     const price = calculatePrice(orderedItem);
        //     if (price > 0) {
        //         return "paying in advance";
        //     } else {
        //         return "donating";
        //     }
        // } else {
        //     return "donating";
        // }
    };

    let due = 0;
    if (orderedItem) {
        due = calculatePrice(orderedItem);
    }

    return (
        <Box m={1} p={1} pb={5}>
            <Paper elevation={3}>
                <Box m={1} p={2} maxWidth={400} margin="auto">
                    <Typography gutterBottom variant="h6" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                        Thank you!
                    </Typography>
                    {!busyMessage && (
                        <Typography
                            gutterBottom
                            variant="body1"
                            component="div"
                            textAlign="center"
                            sx={{ paddingTop: 2 }}
                        >
                            Your order has been placed. I will review your order and get back to you soon{" "}
                            <TagFacesIcon />
                        </Typography>
                    )}
                    {busyMessage && (
                        <>
                            <Box marginTop={1} p={0} textAlign="center">
                                <img width={100} src="/global-images/busy.png" alt={busyMessage} />
                            </Box>
                            <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                textAlign="center"
                                sx={{ paddingTop: 2 }}
                            >
                                {busyMessage}
                            </Typography>
                        </>
                    )}

                    <Box
                        textAlign="center"
                        margin="auto"
                        sx={{ backgroundColor: "#ccff90", borderRadius: 5 }}
                        my={2}
                        p={1}
                    >
                        <Typography variant="subtitle1" width="100%" textAlign="center">
                            Order ID: <b>{location.state.order.id}</b>
                        </Typography>
                        <Button onClick={() => navigate("/my-orders")} endIcon={<ArrowOutwardIcon />}>
                            Track orders
                        </Button>
                    </Box>

                    <Box
                        p={1}
                        textAlign="center"
                        alignSelf="center"
                        width="100%"
                        sx={{ background: "rgb(255, 244, 229)", borderRadius: 5 }}
                    >
                        <Stack direction="column" spacing={2} textAlign="center">
                            <Typography variant="body1">
                                Please consider {renderPayMsg()} if you want me to start the painting on priority
                            </Typography>
                            <Typography variant="body1" fontWeight="bold">
                                UPI ID:
                            </Typography>
                            <Typography variant="body1" fontWeight="bold">
                                kishore5242@paytm
                            </Typography>
                            {/* <div>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<CurrencyRupeeIcon />}
                                        href={`upi://pay?pa=kishore5242@paytm&pn=Kishore Kambali&cu=INR&am=${
                                            due > 0 ? due : 100
                                        }`}
                                    >
                                        Click to Pay {due > 0 ? due : ""} using UPI
                                    </Button>
                                </div> */}
                            <Box width="100%" display="block">
                                <img
                                    className="qr-code"
                                    width={250}
                                    src="/payment-images/qr-code.png"
                                    alt="QR code for payments"
                                />
                            </Box>
                        </Stack>
                    </Box>

                    <Typography gutterBottom variant="body2" component="div" textAlign="center" sx={{ paddingTop: 2 }}>
                        Feel free to direct message me on Instagram
                    </Typography>
                    <Box m={2} p={0} textAlign="center">
                        <Stack direction="row" justifyContent="space-evenly">
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<InstagramIcon />}
                                href="https://ig.me/m/kishorekambali"
                            >
                                Message
                            </Button>
                            <Button
                                variant="contained"
                                color="info"
                                startIcon={<PermContactCalendarIcon />}
                                href="/about"
                            >
                                Contact
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default OrderDone;
