import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { createContext, useEffect, useMemo, useState } from "react";
import { db, storage } from "../firebase";
import { Setting } from "../model/admin.model";
import { ServiceItem } from "../model/order.model";
import { Painting } from "../model/painting.model";

interface IProp {
    children: React.ReactNode;
}

export interface IAppContext {
    settings: Setting[];
    services: ServiceItem[];
    loading: boolean;
    loadingPaintings: boolean;
    paintings: Painting[];
    user: User | undefined;
    shorts: string[];
}

const itemComparator = (a: ServiceItem, b: ServiceItem) => {
    if (b.closed) {
        return -1;
    }
    return a.price - b.price;
};

const AppContext = createContext<IAppContext | null>(null);

export const AppContextProvider = ({ children }: IProp) => {
    const [settings, setSettings] = useState<Setting[]>([]);
    const [services, setServices] = useState<ServiceItem[]>([]);

    const [paintings, setPaintings] = useState<Painting[]>([]);
    const [loadingPaintings, setLoadingPaintings] = useState(true);
    const [shorts, setShorts] = useState<string[]>([]);

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User | undefined>();
    const collectionSettings = collection(db, "settings");
    const collectionServices = collection(db, "services");
    const collectionPaintings = collection(db, "paintings");

    useEffect(() => {
        const listRef = ref(storage, "videos/shorts");

        listAll(listRef)
            .then(async (res) => {
                const urls: string[] = [];
                for (let i = 0; i < res.items.length; i++) {
                    const urlStr = await getDownloadURL(res.items[i]);
                    let url = new URL(urlStr);
                    let params = new URLSearchParams(url.search);
                    params.delete("token");
                    url.search = params.toString();
                    urls.push(url.toString());
                }
                urls.reverse();
                setShorts(urls);
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
            });
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(collectionSettings, (querySnapshot) => {
            const items: Setting[] = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data() as Setting);
            });
            setSettings(items);
            setLoading(false);
        });
        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsub = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(undefined);
            }
        });
        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(collectionServices, (querySnapshot) => {
            const items: ServiceItem[] = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data() as ServiceItem);
            });
            items.sort(itemComparator);
            setServices(items);
            setLoading(false);
        });
        return () => {
            unsub();
        };
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(collectionPaintings, (querySnapshot) => {
            const ps: Painting[] = [];
            querySnapshot.forEach((doc) => {
                ps.push(doc.data() as Painting);
            });
            setPaintings(ps);
            setLoadingPaintings(false);
        });
        return () => {
            unsub();
        };
    }, []);

    const context = useMemo(() => {
        return { settings, services, loading, loadingPaintings, paintings, user, shorts };
    }, [settings, services, loading, loadingPaintings, paintings, user, shorts]);

    return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppContext;
