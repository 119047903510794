export const suffixToFileName = (filename: string, suffix: string) => {
    // Split the file name and extension
    const dotIndex = filename.lastIndexOf(".");
    const baseName = filename.slice(0, dotIndex);
    const extension = filename.slice(dotIndex);

    // Concatenate the base name, suffix, and extension
    const newFileName = `${baseName}_${suffix}${extension}`;

    return newFileName;
};
