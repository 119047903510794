import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";

import { Box, Breadcrumbs, Link, Skeleton } from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import AppContext, { IAppContext } from "../../contexts/app-context";
import { Painting } from "../../model/painting.model";
import { formatPrice } from "../../utils/calculations";
import { PaintingPhotos } from "./painting-photos";

interface IProps {
    title: string;
}

export default function PaintingDetails(props: IProps) {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        document.title = props.title;
    }, []);

    const { loadingPaintings, paintings } = useContext(AppContext) as IAppContext;

    const [painting, setPainting] = useState<Painting | undefined>();
    const [selectedPhoto, setSelectedPhoto] = useState<string>("");

    useEffect(() => {
        if (id) {
            const foundPainting = paintings.find((p) => p.id === parseInt(id, 10));
            setPainting(foundPainting);
            if (foundPainting) {
                setSelectedPhoto(foundPainting.photos[0]);
            }
        }
    }, [paintings]);

    return (
        <Box px={1} py={{ xs: 2, sm: 3, md: 3 }} pb={6} className="painting-details">
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Link underline="hover" color="inherit" href="/paintings">
                    Paintings
                </Link>
                <Typography color="text.primary">{painting?.id}</Typography>
            </Breadcrumbs>
            {loadingPaintings && (
                <Box p={3} width="100%" minHeight={500}>
                    {[...Array(1)].map(() => (
                        <Skeleton
                            key="card-skeleton-3"
                            variant="rectangular"
                            width="100%"
                            height={200}
                            sx={{ margin: 1 }}
                            animation="wave"
                        />
                    ))}
                </Box>
            )}
            {painting && (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={{ xs: 0, sm: 1 }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <PaintingPhotos painting={painting} />
                    </Grid>
                    <Grid item xs={12} lg={6} display="flex" justifyContent="center" alignSelf="flex-start">
                        <Stack spacing={2} p={{ xs: 1, sm: 10, md: 6, lg: 0 }} py={{ lg: 4 }}>
                            <Typography variant="h6" fontWeight={800} color="text.secondary">
                                {`${painting.name} (#${painting.id})`}
                            </Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary">
                                {painting.description}
                            </Typography>
                            <Divider />
                            <Typography variant="body1" color="text.secondary" textAlign="left">
                                <b>{"Size: "} </b>
                                {painting.size}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" textAlign="left">
                                <b>Material: </b>
                                {painting.material}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" textAlign="left">
                                <b>Medium: </b>
                                {painting.medium}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" textAlign="left">
                                <b>Painting number: </b>
                                {painting.id}
                            </Typography>
                            <Divider />
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h5" sx={{ paddingTop: 1 }}>
                                    {formatPrice(painting.price)}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    endIcon={<ShoppingBagTwoToneIcon />}
                                    onClick={() => {}}
                                    size="large"
                                    sx={{ width: 200 }}
                                    onClickCapture={() => navigate(`/paintings/${painting.id}/buy`)}
                                >
                                    Buy
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
