import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ServiceItem } from "../../model/order.model";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import "./order.scss";

import { Box, Button, Stack } from "@mui/material";
import { storeServiceItem } from "../../service/local-storage.service";
import { getRandomColor } from "../util/js-util";
import DecoratedPrice from "./decorated-price";

interface IProps {
    service: ServiceItem;
}

export default function ServiceItemModern(props: IProps) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/service/${props.service.sku}`);
    };

    const handleOrder = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(`/service/${props.service.sku}`);

        // Directly take user to checkout
        // storeServiceItem(props.service);
        // navigate("/checkout");
    };

    return (
        <Card
            sx={{
                display: "flex",
                minHeight: "240px",
                cursor: "pointer",
                background: getRandomColor(props.service.sku),
            }}
            onClick={handleClick}
        >
            <CardMedia
                component="img"
                sx={{ maxWidth: "30%", minWidth: 130 }}
                image={props.service.images[0]}
                alt={props.service.sku}
            />
            <CardContent sx={{ width: "100%" }}>
                <Stack
                    height="100%"
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Typography variant="subtitle2" component="div" color="#616161">
                        <b>{props.service.title}</b>
                    </Typography>
                    <Divider />
                    <Typography variant="body2" color="text.secondary" textAlign="left">
                        <b>Material: </b>
                        {props.service.material}
                    </Typography>
                    <Divider />
                    <DecoratedPrice service={props.service} />
                    <Box sx={{ width: "100%", paddingTop: "10px", display: "flex" }}>
                        <Button
                            variant="contained"
                            color="warning"
                            endIcon={<NavigateNextIcon />}
                            onClick={(e) => handleOrder(e)}
                            disabled={!!props.service.closed}
                            fullWidth
                            sx={{ width: "100%" }}
                        >
                            Details
                        </Button>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}
