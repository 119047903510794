import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import AppContext, { IAppContext } from "../contexts/app-context";
import { isEmailValid, isFieldValid } from "../utils/validations";

const LoginBasic = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AppContext) as IAppContext;

    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState(" ");

    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState(" ");

    const [name, setName] = useState("");
    const [nameValid, setNameValid] = useState(false);
    const [nameTouched, setNameTouched] = useState(true);
    const [nameHelperText, setNameHelperText] = useState(" ");

    const [existingUser, setExistingUser] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = e.target.value.trim();
        setEmail(enteredValue);
        const isEnteredEmailValid = isEmailValid(enteredValue);
        setEmailValid(isEnteredEmailValid);
        if (emailTouched) {
            if (isEnteredEmailValid) {
                setEmailHelperText(" ");
            } else {
                setEmailHelperText("Email entered is invalid");
            }
        }
        setExistingUser(true);
    };

    const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setEmailTouched(true);
        if (emailValid) {
            setEmailHelperText(" ");
        } else {
            setEmailHelperText("Email entered is invalid");
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = e.target.value.trim();
        setPassword(enteredValue);
        const isEnteredPasswordValid = isFieldValid(enteredValue);
        setPasswordValid(isEnteredPasswordValid);
        if (passwordTouched) {
            if (isEnteredPasswordValid) {
                setPasswordHelperText(" ");
            } else {
                setPasswordHelperText("Password is required");
            }
        }
    };

    const handlePasswordBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setPasswordTouched(true);
        if (passwordValid) {
            setPasswordHelperText(" ");
        } else {
            setPasswordHelperText("Password is required");
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = e.target.value;
        setName(enteredValue);
        const isEnteredNameValid = isFieldValid(enteredValue);
        setNameValid(isEnteredNameValid);
        if (nameTouched) {
            if (isEnteredNameValid) {
                setNameHelperText(" ");
            } else {
                setNameHelperText("Name is required");
            }
        }
    };

    const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setNameTouched(true);
        if (nameValid) {
            setNameHelperText(" ");
        } else {
            setNameHelperText("Name is required");
        }
    };

    const handleForgotPassword = async () => {
        // const isEnteredEmailValid = isEmailValid(email);
        // setEmailValid(isEnteredEmailValid);
        // setEmailTouched(true);
        // isEnteredEmailValid ? setEmailHelperText(" ") : setEmailHelperText("Valid Email is needed to reset password");
        // if (isEnteredEmailValid) {
        //     // check if user is existing
        //     const isExistingUser = await userCheck(email).then((r) => r);
        //     if (!isExistingUser) {
        //         setSaControls({
        //             open: true,
        //             message: "Email not registered with us. Please SIGN UP instead",
        //             severity: "warning",
        //         });
        //         setExistingUser(false);
        //     } else {
        //         navigate("/forgot-password", location);
        //     }
        // }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitting(true);

        const isEnteredEmailValid = isEmailValid(email);
        setEmailValid(isEnteredEmailValid);
        setEmailTouched(true);
        isEnteredEmailValid ? setEmailHelperText(" ") : setEmailHelperText("Email entered is invalid");

        const isPassowrdEnteredValid = isFieldValid(password);
        setPasswordValid(isPassowrdEnteredValid);
        setPasswordTouched(true);
        isPassowrdEnteredValid ? setPasswordHelperText(" ") : setPasswordHelperText("Password is required");

        // check if email and password are valid
        if (!isEnteredEmailValid || !isPassowrdEnteredValid) {
            setSubmitting(false);
            return;
        }

        const auth = getAuth();
        // createUserWithEmailAndPassword(auth, email, password)
        //     .then((userCredential) => {
        //         const user = userCredential.user;
        //         console.log(user);
        //         const redirectTo = location.state?.redirectRoute;
        //         navigate(redirectTo ? redirectTo : "/");
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                const redirectTo = location.state?.redirectRoute;
                navigate(redirectTo ? redirectTo : "/");
            })
            .catch((error) => {
                setEmailValid(false);
                setPasswordValid(false);
                setSubmitting(false);
            });

        // // check if user is existing
        // userCheck(email).then((isExistingUser) => {
        //     setExistingUser(isExistingUser);

        //     // sign up if new user
        //     if (!isExistingUser) {
        //         const isEnteredNameValid = isFieldValid(name);
        //         setNameValid(isEnteredNameValid);
        //         setNameTouched(true);
        //         isEnteredNameValid ? setNameHelperText(" ") : setNameHelperText("Name is required");
        //         if (!isEnteredNameValid) {
        //             setSubmitting(false);
        //             return;
        //         }

        //         signup({ name, email, password })
        //             .then(() => {
        //                 setSubmitting(false);
        //                 const redirectTo = location.state?.redirectRoute;
        //                 navigate(redirectTo ? redirectTo : "/");
        //             })
        //             .catch((e) => setSubmitting(false));
        //     }

        //     // login if existing user
        //     if (isExistingUser) {
        //         login({ email, password })
        //             .then((response) => {
        //                 localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        //                 getCurrentUser()
        //                     .then((response) => {
        //                         addCurrentUser(response);
        //                         setSubmitting(false);
        //                         const redirectTo = location.state?.redirectRoute;
        //                         navigate(redirectTo ? redirectTo : "/");
        //                     })
        //                     .catch((error) => {
        //                         const errorMessage =
        //                             (error && error.message) || "Oops! Something went wrong. Please try again!";
        //                         setSaControls({ open: true, message: errorMessage, severity: "error" });
        //                         setSubmitting(false);
        //                     });
        //             })
        //             .catch((error) => {
        //                 let errorMessage = (error && error.message) || "Oops! Something went wrong. Please try again!";
        //                 if (error?.status === 401) {
        //                     errorMessage = "Invalid password";
        //                 }
        //                 setSaControls({ open: true, message: errorMessage, severity: "error" });
        //                 setPasswordHelperText("Password you have entered is incorrect");
        //                 setPasswordValid(false);
        //                 setSubmitting(false);
        //             });
        //     }
        // });
    };

    return (
        <Stack
            direction="column"
            p={4}
            spacing={{ xs: 2, sm: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", maxWidth: "400px" }}
        >
            <Typography gutterBottom variant="h6" component="div" textAlign="center">
                Sign in
            </Typography>
            <TextField
                label="Email"
                type="text"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailTwoToneIcon />
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={emailTouched && !emailValid}
                required
                helperText={emailHelperText}
                fullWidth
            />
            <Box pb={3}>
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyTwoToneIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    error={passwordTouched && !passwordValid}
                    required
                    helperText={passwordHelperText}
                    fullWidth
                />
                <Typography component="a" onClick={() => handleForgotPassword()}>
                    Forgot password?
                </Typography>
            </Box>

            {!existingUser && (
                <>
                    <Typography gutterBottom variant="body1" component="div" textAlign="center">
                        Looks like you are a new user. Sign up by giving your name
                    </Typography>
                    <TextField
                        label="Name"
                        variant="outlined"
                        type="text"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonOutlineTwoToneIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={name}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        error={nameTouched && !nameValid}
                        required
                        helperText={nameHelperText}
                        fullWidth
                    />
                </>
            )}

            <Button
                variant="contained"
                color="success"
                size="large"
                endIcon={submitting ? <CircularProgress /> : <ArrowForwardIosTwoToneIcon />}
                onClick={handleSubmit}
                fullWidth
                sx={{ height: "60px" }}
                disabled={submitting}
            >
                {existingUser ? "Sign in" : "Sign up"}
            </Button>
        </Stack>
    );
};

export default LoginBasic;
