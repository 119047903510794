import { Breadcrumbs, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import PageTitle from "./page-title";
interface IProps {
    title: string;
}
const PrivacyPolicy = (props: IProps) => {
    useEffect(() => {
        document.title = props.title;
    }, []);

    return (
        <Box pb={5} py={{ xs: 2, sm: 3 }} px={1}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">Privacy policy</Typography>
            </Breadcrumbs>
            <PageTitle>Privacy policy</PageTitle>
            <Stack spacing={2} direction="column" justifyContent="space-around" alignItems="left" px={3}>
                <Typography variant="h6">1. Introduction</Typography>
                <Typography variant="body1">
                    Welcome to Kishore Kambali. Kishore Kambali (“us”, “we”, or “our”) operates
                    https://kishorekambali.in (hereinafter referred to as “Service”). Our Privacy Policy governs your
                    visit to https://kishorekambali.in, and explains how we collect, safeguard and disclose information
                    that results from your use of our Service. We use your data to provide and improve Service. By using
                    Service, you agree to the collection and use of information in accordance with this policy. Unless
                    otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same
                    meanings as in our Terms and Conditions. Our Terms and Conditions (“Terms”) govern all use of our
                    Service and together with the Privacy Policy constitutes your agreement with us (“agreement”).
                </Typography>
                <Typography variant="h6">2. Definitions</Typography>

                <Typography variant="body1">
                    SERVICE means the https://kishorekambali.in website operated by Kishore Kambali. PERSONAL DATA means
                    data about a living individual who can be identified from those data (or from those and other
                    information either in our possession or likely to come into our possession). USAGE DATA is data
                    collected automatically either generated by the use of Service or from Service infrastructure itself
                    (for example, the duration of a page visit). COOKIES are small files stored on your device (computer
                    or mobile device). DATA CONTROLLER means a natural or legal person who (either alone or jointly or
                    in common with other persons) determines the purposes for which and the manner in which any personal
                    data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller
                    of your data. DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal person who processes
                    the data on behalf of the Data Controller. We may use the services of various Service Providers in
                    order to process your data more effectively. DATA SUBJECT is any living individual who is the
                    subject of Personal Data. THE USER is the individual using our Service. The User corresponds to the
                    Data Subject, who is the subject of Personal Data.
                </Typography>

                <Typography variant="h6">3. Information Collection and Use</Typography>
                <Typography variant="body1">
                    We collect several different types of information for various purposes to provide and improve our
                    Service to you.
                </Typography>

                <Typography variant="h6">4. Types of Data Collected Personal Data</Typography>
                <Typography variant="body1">
                    While using our Service, we may ask you to provide us with certain personally identifiable
                    information that can be used to contact or identify you (“Personal Data”). Personally identifiable
                    information may include, but is not limited to: 0.1. Email address 0.2. First name and last name
                    0.3. Phone number 0.4. Address, Country, State, Province, ZIP/Postal code, City 0.5. Cookies and
                    Usage Data We may use your Personal Data to contact you with newsletters, marketing or promotional
                    materials and other information that may be of interest to you. You may opt out of receiving any, or
                    all, of these communications from us by following the unsubscribe link. Usage Data We may also
                    collect information that your browser sends whenever you visit our Service or when you access
                    Service by or through any device (“Usage Data”). This Usage Data may include information such as
                    your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the
                    pages of our Service that you visit, the time and date of your visit, the time spent on those pages,
                    unique device identifiers and other diagnostic data. When you access Service with a device, this
                    Usage Data may include information such as the type of device you use, your device unique ID, the IP
                    address of your device, your device operating system, the type of Internet browser you use, unique
                    device identifiers and other diagnostic data. Location Data We may use and store information about
                    your location if you give us permission to do so (“Location Data”). We use this data to provide
                    features of our Service, to improve and customize our Service. You can enable or disable location
                    services when you use our Service at any time by way of your device settings. Tracking Cookies Data
                    We use cookies and similar tracking technologies to track the activity on our Service and we hold
                    certain information. Cookies are files with a small amount of data which may include an anonymous
                    unique identifier. Cookies are sent to your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags and scripts to collect and track
                    information and to improve and analyze our Service. You can instruct your browser to refuse all
                    cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may
                    not be able to use some portions of our Service. Examples of Cookies we use: 0.1. Session Cookies:
                    We use Session Cookies to operate our Service. 0.2. Preference Cookies: We use Preference Cookies to
                    remember your preferences and various settings. 0.3. Security Cookies: We use Security Cookies for
                    security purposes. 0.4. Advertising Cookies: Advertising Cookies are used to serve you with
                    advertisements that may be relevant to you and your interests. Other Data While using our Service,
                    we may also collect the following information: sex, age, date of birth, place of birth, passport
                    details, citizenship, registration at place of residence and actual address, telephone number (work,
                    mobile), details of documents on education, qualification, professional training, employment
                    agreements, NDA agreements, information on bonuses and compensation, information on marital status,
                    family members, social security (or other taxpayer identification) number, office location and other
                    data.
                </Typography>

                <Typography variant="h6">5. Use of Data</Typography>
                <Typography variant="body1">
                    Kishore Kambali uses the collected data for various purposes: 0.1. to provide and maintain our
                    Service; 0.2. to notify you about changes to our Service; 0.3. to allow you to participate in
                    interactive features of our Service when you choose to do so; 0.4. to provide customer support; 0.5.
                    to gather analysis or valuable information so that we can improve our Service; 0.6. to monitor the
                    usage of our Service; 0.7. to detect, prevent and address technical issues; 0.8. to fulfil any other
                    purpose for which you provide it; 0.9. to carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us, including for billing and collection; 0.10. to
                    provide you with notices about your account and/or subscription, including expiration and renewal
                    notices, email-instructions, etc.; 0.11. to provide you with news, special offers and general
                    information about other goods, services and events which we offer that are similar to those that you
                    have already purchased or enquired about unless you have opted not to receive such information;
                    0.12. in any other way we may describe when you provide the information; 0.13. for any other purpose
                    with your consent.
                </Typography>

                <Typography variant="h6">6. Retention of Data</Typography>
                <Typography variant="body1">
                    We will retain your Personal Data only for as long as is necessary for the purposes set out in this
                    Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
                    legal obligations (for example, if we are required to retain your data to comply with applicable
                    laws), resolve disputes, and enforce our legal agreements and policies. We will also retain Usage
                    Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except
                    when this data is used to strengthen the security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time periods.
                </Typography>

                <Typography variant="h6">7. Transfer of Data</Typography>
                <Typography variant="body1">
                    Your information, including Personal Data, may be transferred to – and maintained on – computers
                    located outside of your state, province, country or other governmental jurisdiction where the data
                    protection laws may differ from those of your jurisdiction. If you are located outside India and
                    choose to provide information to us, please note that we transfer the data, including Personal Data,
                    to India and process it there. Your consent to this Privacy Policy followed by your submission of
                    such information represents your agreement to that transfer. Kishore Kambali will take all the steps
                    reasonably necessary to ensure that your data is treated securely and in accordance with this
                    Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country
                    unless there are adequate controls in place including the security of your data and other personal
                    information.
                </Typography>

                <Typography variant="h6">8. Disclosure of Data</Typography>
                <Typography variant="body1">
                    We may disclose personal information that we collect, or you provide: 0.1. Disclosure for Law
                    Enforcement. Under certain circumstances, we may be required to disclose your Personal Data if
                    required to do so by law or in response to valid requests by public authorities. 0.2. Business
                    Transaction. If we or our subsidiaries are involved in a merger, acquisition or asset sale, your
                    Personal Data may be transferred. 0.3. Other cases. We may disclose your information also: 0.3.1. to
                    our subsidiaries and affiliates; 0.3.2. to contractors, service providers, and other third parties
                    we use to support our business; 0.3.3. to fulfill the purpose for which you provide it; 0.3.4. for
                    the purpose of including your company’s logo on our website; 0.3.5. for any other purpose disclosed
                    by us when you provide the information; 0.3.6. with your consent in any other cases; 0.3.7. if we
                    believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                    Company, our customers, or others.
                </Typography>

                <Typography variant="h6">9. Security of Data</Typography>
                <Typography variant="body1">
                    The security of your data is important to us but remember that no method of transmission over the
                    Internet or method of electronic storage is 100% secure. While we strive to use commercially
                    acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                </Typography>

                <Typography variant="h6">10. Children’s Privacy</Typography>
                <Typography variant="body1">
                    Our Services are not intended for use by children under the age of 18 (“Child” or “Children”). We do
                    not knowingly collect personally identifiable information from Children under 18. If you become
                    aware that a Child has provided us with Personal Data, please contact us. If we become aware that we
                    have collected Personal Data from Children without verification of parental consent, we take steps
                    to remove that information from our servers.
                </Typography>

                <Typography variant="h6">11. Contact</Typography>
                <Typography variant="body1">
                    If you have any questions about this Privacy Policy, please contact us by email:
                    kishore.a.social@gmail.com.
                </Typography>
            </Stack>
        </Box>
    );
};

export default PrivacyPolicy;
