import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Painting } from "../../model/painting.model";
import { getThumbnailUrl } from "../util/net-util";
import "./painting-photos.scss";

export interface IProps {
    painting: Painting;
}

export const PaintingPhotos = (props: IProps) => {
    const [selectedPhoto, setSelectedPhoto] = useState<string>("");

    useEffect(() => {
        if (props.painting) {
            setSelectedPhoto(props.painting.photos[0]);
        }
    }, [props.painting]);

    return (
        <Stack
            direction={{ xs: "column-reverse", lg: "row" }}
            spacing={2}
            mt={{ xs: 1, md: 5 }}
            className="painting-photos"
        >
            <Box>
                <Stack direction={{ xs: "row", lg: "column" }} className="thumbnail-area">
                    {props.painting.photos.map((photo) => (
                        <Box
                            className={`thumbnail-container ${photo !== selectedPhoto ? "transparent" : ""}`}
                            key={photo}
                            onClick={() => setSelectedPhoto(photo)}
                            width={100}
                            height={100}
                        >
                            <img
                                className="thumbnail"
                                alt={`${props.painting.name} by Kishore Anand (@kishorekambali)`}
                                src={getThumbnailUrl(photo)}
                            />
                        </Box>
                    ))}
                </Stack>
            </Box>
            <Box
                component="img"
                sx={{
                    maxWidth: "100%",
                    minHeight: "300px",
                    maxHeight: "80vh",
                    borderRadius: "5px",
                    boxShadow: "10px 10px 10px gray",
                }}
                alt={`${props.painting.name} by Kishore Anand (@kishorekambali)`}
                src={selectedPhoto}
            />
        </Stack>
    );
};
