import { Box, Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ServicesBoard from "../../order/services-board";
import PaintingsTop from "../../showcase/paintings-top";
import { debounce } from "../../util/js-util";
import "./profile.scss";
import VideoPort from "./video-port";

const Profile = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const containers = Array.from(
                document.getElementsByClassName("container") as HTMLCollectionOf<HTMLElement>
            );

            containers.forEach((cont) => {
                const rect = cont.getBoundingClientRect();

                if (rect.top < window.innerHeight && rect.bottom > 0) {
                    const textCont = cont.querySelector(".text-container") as HTMLElement;
                    if (textCont) {
                        const btm = (rect.bottom / window.innerHeight) * 170;
                        textCont.style.bottom = `${btm}px`;
                    }

                    // const otherCont = cont.querySelector(".other-container") as HTMLElement;
                    // if (otherCont) {
                    //     const btm = (rect.bottom / window.innerHeight) * 180;
                    //     otherCont.style.bottom = `${btm}px`;
                    // }
                }
            });
        };

        handleScroll();

        const debouncedHandleScroll = debounce(handleScroll, 5);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () => window.removeEventListener("scroll", debouncedHandleScroll);
    }, []);

    return (
        <div className="profile">
            <div className="container intro-container">
                <div className="text-container even" id="tc1">
                    <div className="text">
                        Welcome to my artistic haven! I am Kishore Anand, a passionate software engineer based in
                        Bangalore, who has found solace and joy in the vibrant world of art. I dedicate my weekends to
                        this fulfilling artistic pursuit, a hobby I've nurtured since 2022.
                    </div>
                </div>
            </div>

            <div className="container cat-comm-container">
                <div className="text-container odd">
                    <div className="text">
                        Specializing in commissioned portrait paintings, I delight in capturing the essence of
                        individuals. Let me turn your cherished moments into timeless works of art.
                        <ServicesBoard title="" />
                    </div>
                </div>
            </div>

            <VideoPort url="https://firebasestorage.googleapis.com/v0/b/artist-kishore.appspot.com/o/videos%2Fshorts%2Fwoman-bangla.mp4?alt=media" />

            <div className="container cat-int-container">
                <div className="text-container even">
                    <div className="text">
                        I also bring to life a diverse array of subjects, with a particular penchant for portraying
                        Hindu gods and goddesses.
                    </div>
                </div>
            </div>

            <VideoPort url="https://firebasestorage.googleapis.com/v0/b/artist-kishore.appspot.com/o/videos%2Fshorts%2Fmadhusudhana.mp4?alt=media" />

            <div className="container cat-buddha-container">
                <div className="text-container odd">
                    <div className="text">
                        Painting Buddha holds a special place in my heart; it goes beyond mere artistic expression for
                        me. It becomes a meditative experience that brings unparalleled calmness to my mind.
                    </div>
                </div>
            </div>

            <VideoPort url="https://firebasestorage.googleapis.com/v0/b/artist-kishore.appspot.com/o/videos%2Fshorts%2Fcolorful_buddha.mp4?alt=media" />

            <div className="container focused">
                <div className="blur" />
                <div className="other-container mid">
                    <PaintingsTop title="" />
                </div>
            </div>

            <div className="footer">
                <div className="goto-links">
                    <Button
                        className="goto-link"
                        variant="text"
                        size="medium"
                        color="inherit"
                        onClick={() => navigate("/services")}
                        sx={{ margin: "auto" }}
                    >
                        Services
                    </Button>
                    <Button
                        className="goto-link"
                        variant="text"
                        size="medium"
                        color="inherit"
                        onClick={() => navigate("/paintings")}
                        sx={{ margin: "auto" }}
                    >
                        Paintings
                    </Button>
                    <Button
                        className="goto-link"
                        variant="text"
                        size="medium"
                        color="inherit"
                        onClick={() => navigate("/gallery")}
                        sx={{ margin: "auto" }}
                    >
                        Gallery
                    </Button>
                    <Button
                        className="goto-link"
                        variant="text"
                        size="medium"
                        color="inherit"
                        onClick={() => navigate("/about")}
                        sx={{ margin: "auto" }}
                    >
                        About
                    </Button>
                </div>

                <div className="links">
                    <Box
                        component="img"
                        className="link-image"
                        alt="Linkedin logo"
                        src="/login-images/linkedin-logo.png"
                        onClick={() => window.open("https://in.linkedin.com/in/kishore-anand-01ab7669", "_blank")}
                    />
                    <Box
                        component="img"
                        className="link-image"
                        alt="Instagram logo"
                        src="/login-images/instagram-logo.png"
                        onClick={() => window.open("https://instagram.com/kishorekambali", "_blank")}
                    />
                    <Box
                        component="img"
                        className="link-image"
                        alt="YouTube logo"
                        src="/login-images/youtube-logo.png"
                        onClick={() => window.open("https://www.youtube.com/@kishorekambali", "_blank")}
                    />
                    <Box
                        component="img"
                        className="link-image"
                        alt="Gmail logo"
                        src="/login-images/gmail-logo.png"
                        onClick={() => window.open("mailto:kishore.a.social@gmail.com", "_blank")}
                    />
                </div>
            </div>
        </div>
    );
};

export default Profile;
